import { makeAutoObservable, toJS } from 'mobx';

interface IEmpInfoData {
  accessCode: string;
  accountId: number;
  archived: number;
  archivedTime: null;
  archivist: null;
  createTime: number;
  creator: number;
  dashboardAccess: number;
  email: string;
  employeeId: number;
  firstName: string;
  fullName: string;
  hourlyRate: null;
  isCreateAccount: null;
  lastName: string;
  modifiedTime: number;
  modifier: number;
  phoneNumber: string;
  tag: number;
  tenantId: number;
}
interface IShopInfoData {
  createdTime: number;
  modifiedTime: number;
  name: string;
  nodeId: number;
  orgId: number;
  orgName: string;
  orgType: number;
  relatedId: number;
  tenantId: string;
  timeZone: string;
  timeZoneId: string;
}

interface IEmployeeMenusRes {
  displayOrder: number;
  icon: string;
  id: number;
  name: string;
  parentId: number;
  subEntrances: IEmployeeMenusRes[] | null;
  url: string;
}
class UserInfoStore {
  userInfo: IEmpInfoData | null = null;
  shopInfo: IShopInfoData | null = null;
  permissionIds: number[] = JSON.parse(localStorage.getItem('permissionIds')) || [];
  employeeMenusRes: IEmployeeMenusRes[] | null = null;

  constructor () {
    makeAutoObservable(this);
  }

  setUserInfo (userInfo: IEmpInfoData) {
    this.userInfo = { ...userInfo };
  }
  setShopInfo (shopInfo: IShopInfoData) {
    this.shopInfo = { ...shopInfo };
  }
  setPermissionIds (permissionIds: number[]) {
    this.permissionIds = permissionIds;
  }
  setEmployeeMenusRes (employeeMenusRes: IEmployeeMenusRes[]) {
    this.employeeMenusRes = employeeMenusRes;
  }
  clearShopInfo () {
    this.shopInfo = null;
  }
  clearUserInfo () {
    this.userInfo = null;
  }
  clearPermissionIds () {
    this.permissionIds = [];
  }
  clearEmployeeMenusRes () {
    this.employeeMenusRes = null;
  }
  get getUserInfo () {
    return this.userInfo;
  }
  get getShopInfo () {
    return this.shopInfo;
  }
  get getPermissionIds () {
    return this.permissionIds;
  }
  get getEmployeeMenusRes () {
    return toJS(this.employeeMenusRes);
  }
}

const UserInfo = new UserInfoStore();
export default UserInfo;
