import React, { useEffect, useState } from 'react';
import { globalState } from '@/stores';
import './index.scss';
import { Button, Form, Select, Table } from 'antd';
import PepprDatePicker, { DATE_RANGE_TYPE } from '@/Components/DatePicker';
import {
  formatTime,
  getToday,
  removeNullUndefinedFields,
} from '@/utils';
import { ApiExportCashDrawerOverview, ApiQueryCashDrawerOverview } from '@/request/api';
import useGetState from '@/hooks/useGetState';
import dayjs, { Dayjs } from 'dayjs';
import Columns from '@/Components/Columns/Columns';
import { ColumnsType } from 'antd/es/table';
import { TableEmptyContent } from '@/Components/EmptyContent';
import axios from 'axios';
import {
  ICashDrawerRowData,
  IPage,
} from './types';
import { SyncOutlined } from '@ant-design/icons';
import { IDropMenuExportParams, IExportResult } from '@/Components/Export/interface';
import { EReport_Status } from '@/types';
import PepprExportComponent from '@/Components/Export/PepprExportComponent';
import { getCashDrawerList } from '@/request/PrintersAndCashDrawers';

let axiosSource = axios.CancelToken.source();

export default function CashDrawerOverview () {
  const { i18n } = globalState;

  const [isShowEmptyTable, setIsShowEmptyTable] = useState<boolean>(false);
  const [rangePresets, setRangePresets] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showColumnsList, setShowColumnsList] = useState<Array<string>>([]);
  const [tableData, setTableData] = useState<Array<ICashDrawerRowData>>([]);
  const [today, setToday] = useState<Dayjs>(dayjs());
  const [cashDrawerList, setCashDrawerList] = useState<Array<any>>([]);

  const [pager, setPager, getPager] = useGetState<IPage>({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  });

  const [, setParams, getParams] = useGetState<{ dateRangeType: string }>({
    dateRangeType: DATE_RANGE_TYPE.TODAY,
  });
  const [form] = Form.useForm();

  const formInitialValues = {
    date: [dayjs().startOf('day'), dayjs().endOf('day')],
  };

  const formatCurrency = (value: number): string => {
    const formattedText = Math.abs(value).toFixed(2);
    return value < 0 ?
      `-$${formattedText}` : `$${formattedText}`;
  };


  const statusOpts = {
    1: 'Active',
    2: 'Ended',
  }
  const tableColumns = [
    {
      dataIndex: 'cashDrawerName',
      key: 'cashDrawerName',
      title: i18n.t('Dashboard_report_cashDrawerOverview_cash_drawer_Name'),
      width: 220,
    },
    {
      dataIndex: 'openTime',
      key: 'openTime',
      title: i18n.t('Dashboard_report_cashDrawerOverview_start_time'),
      width: 220,
      render: (text) => {
        if (text) {
          return formatTime(text, 'MM/dd/yyyy hh:mm tt');
        } else  {
          return '-';
        }
      }
    },
    {
      dataIndex: 'closeTime',
      key: 'closeTime',
      title: i18n.t('Dashboard_report_cashDrawerOverview_end_time'),
      width: 220,
      render: (text) => {
        if (text) {
          return formatTime(text, 'MM/dd/yyyy hh:mm tt');
        } else  {
          return '-';
        }
      }
    },
    {
      dataIndex: 'beginningBalance',
      key: 'beginningBalance',
      title: i18n.t('Dashboard_report_cashDrawerOverview_starting_balance'),
      width: 220,
      render: (text) => {
        return formatCurrency(text);
      },
    },
    {
      dataIndex: 'cashPayments',
      key: 'cashPayments',
      title: i18n.t('Dashboard_report_cashDrawerOverview_cash_payment'),
      width: 220,
      render: (text) => {
        return formatCurrency(text);
      },
    },
    {
      dataIndex: 'cashRefund',
      key: 'cashRefund',
      title: i18n.t('Dashboard_report_cashDrawerOverview_cash_refund'),
      width: 220,
      render: (text) => {
        return formatCurrency(text);
      },
    },
    {
      dataIndex: 'cashIn',
      key: 'cashIn',
      title: i18n.t('Dashboard_report_cashDrawerOverview_cash_in'),
      width: 220,
      render: (text) => {
        return formatCurrency(text);
      },
    },
    {
      dataIndex: 'cashOut',
      key: 'cashOut',
      title: i18n.t('Dashboard_report_cashDrawerOverview_cash_out'),
      width: 220,
      render: (text) => {
        return formatCurrency(text);
      },
    },
    {
      dataIndex: 'expectBalance',
      key: 'expectBalance',
      title: i18n.t('Dashboard_report_cashDrawerOverview_expected_balance'),
      width: 220,
      render: (text) => {
        return formatCurrency(text);
      },
    },
    {
      dataIndex: 'endingBalance',
      key: 'endingBalance',
      title: i18n.t('Dashboard_report_cashDrawerOverview_ending_balance'),
      width: 220,
      render: (text) => {
        return formatCurrency(text);
      },
    },
    {
      dataIndex: 'cashOver',
      key: 'cashOver',
      title: i18n.t('Dashboard_report_cashDrawerOverview_difference'),
      width: 220,
      render: (text) => {
        return formatCurrency(text);
      },
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: i18n.t('Dashboard_report_cashDrawerOverview_status'),
      width: 220,
      render: (text) => {
        return statusOpts[text] || '-';
      },
    },
    {
      dataIndex: 'closeEmployee',
      key: 'closeEmployee',
      title: i18n.t('Dashboard_report_cashDrawerOverview_ended_by'),
      width: 220,
    },
  ];

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setShowColumnsList(tableColumns.map((x) => x.dataIndex));
    fetchCashDrawerList();
    const _today = await getToday();
    setToday(_today);
    form.setFieldValue('date', [_today, _today]);
    await fetchTableData();
  };

  const fetchCashDrawerList = async () => {
    const res = await getCashDrawerList({});
    setCashDrawerList(res?.data || []);
  };



  const handleChangeColumns = (list) => {
    setIsShowEmptyTable(list.length === 0);
    setShowColumnsList(list);
  };

  const handleFormValuesChange = ({ date }) => {
    setPager({ ...pager, current: 1 });
    if (date) {
      const dateRangeType =
        rangePresets.find((v) => {
          return v.value[0].isSame(date[0]) && v.value[1].isSame(date[1]);
        })?.key ?? DATE_RANGE_TYPE.CUSTOM;
      setParams({ dateRangeType });
    }
    fetchTableData();
  };

  const handleTableChange = (_pagination, _, sorter) => {
    setPager({ ...pager, ..._pagination });
    fetchTableData();
  };

  const fetchTableData = async () => {
    const pagerParams = getPager();
    const formParams = form.getFieldsValue();
    const curParams = getParams();
    setLoading(true);
    axiosSource.cancel();
    axiosSource = axios.CancelToken.source();
    const resp = await ApiQueryCashDrawerOverview(
      removeNullUndefinedFields({
        request:{
          dateRangeType: curParams.dateRangeType,
          startTime: formParams.date[0].format('YYYY-MM-DD 00:00:00'),
          endTime: formParams.date[1].format('YYYY-MM-DD 23:59:59'),
          cancelToken: axiosSource.token,
          cashDrawerIds: formParams.cashDrawerIds,
        },
        current: pagerParams.current,
        pageSize: pagerParams.pageSize,
      }),
    );
    setLoading(false);
    if (resp?.code !== 0) return;
    const {
      list = [],
      total,
      pageSize,
      current,
    } = resp.data;
    setTableData(list);
    const page = {
      current,
      pageSize,
      total,
    }
    setPager({ ...pagerParams, ...page });
  };


  // 导出相关
  const fetchApiExport = async (exportType: IDropMenuExportParams) => {
    const params = {
      ...getRequestParams(),
      exportType: EReport_Status[exportType],
    }
    const res: any = await ApiExportCashDrawerOverview(removeNullUndefinedFields(params));

    const exportResult: IExportResult = {
      exportStatus: res.code === 0,
      exportId: res?.data?.exportTaskId ?? null,
      exportFailErrorMsg: res,
    }

    return exportResult
  }


  const getRequestParams = () => {
    const formParams = form.getFieldsValue();
    return {
      beginTime: formParams.date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: formParams.date[1].format('YYYY-MM-DD 23:59:59'),
    }
  }

  return (
    <div className="cash-management-wrap">
      <div className="m-title">
        <div className="title">{i18n.t('Dashboard_report_cashDrawerOverview')}</div>
      </div>
      <div className="filter-wrap">
        <div className="left-wrap">
          <Form
            form={form}
            layout="inline"
            onValuesChange={handleFormValuesChange}
            initialValues={formInitialValues}
          >
            <Form.Item name={'date'}>
              <PepprDatePicker today={today} setPresets={setRangePresets} />
            </Form.Item>
            <Form.Item name={'cashDrawerIds'}>
              <Select
                options={cashDrawerList.map(item => ({ label: item.name, value: item.id }))}
                showSearch
                mode="multiple"
                style={{ width: 250 }}
                allowClear
                maxTagCount={2}
                placeholder={i18n.t('All cash ddashboard_reports_cash_drawer_overview_filter_by_cash_drawerrawers')}
                filterOption={(input, option) =>
                  option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </Form.Item>
          </Form>
        </div>
        <div className="right-wrap">
          <Button type="link" onClick={fetchTableData}>
            <SyncOutlined />
          </Button>
          <Columns
            value={showColumnsList}
            options={tableColumns.map((item) => ({
              label: item.title,
              value: item.dataIndex,
            }))}
            onChange={handleChangeColumns}
          />
        </div>
      </div>
      <div className="table-wrap">
        <Table
          className={isShowEmptyTable && 'empty-table'}
          columns={
            tableColumns.filter((x) =>
              showColumnsList.includes(x.dataIndex)
            ) as ColumnsType
          }
          dataSource={tableData.map((x, i) => ({ ...x, key: i }))}
          rowKey={'key'}
          loading={loading}
          pagination={pager}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
          locale={{ emptyText: TableEmptyContent }}
          summary={() =>
            isShowEmptyTable && (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={999}>
                  {TableEmptyContent}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )
          }
        />
      </div>


      <div className="action-wrap">
        <PepprExportComponent
          type='button'
          isDropDown={true}
          disabled={tableData.length === 0}
          fetchApiExport={(exportType) => fetchApiExport(exportType)}
        />
      </div>
    </div>
  );
}
