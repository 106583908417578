import axios from 'axios';
import { message } from 'antd';
import { getCookie, formatBackI18n } from '@/utils';
import store from '@/stores/userInfo';
import { AccountWarning } from '@/Components/AccountWarning';
export const baseURL = '/apaas/api';

// 创建axios实例
function createService (baseURL) {
  const service = axios.create({ baseURL });
  service.interceptors.request.use(requestInterceptor);
  service.interceptors.response.use(responseInterceptor, errorInterceptor);
  return service;
}
// 对比用户的accountId和nodeId
const checkUserInfo = () => {
  const { accountId } = store.getUserInfo || {};
  const { nodeId } = store.getShopInfo || {};
  if (accountId && nodeId) {
    const cookieUserInfo = `${getCookie('accId')}${getCookie('nodeId')}`;
    const storeUserInfo = `${accountId}${nodeId}`;
    return cookieUserInfo === storeUserInfo;
  }
  return true;
};
// 请求拦截器
function requestInterceptor (config) {
  if (store.getUserInfo?.accountId && store.getShopInfo?.nodeId) {
    if (!checkUserInfo()) {
      AccountWarning();
    }
  }
  config.data = Object.assign({}, config.data);
  config.headers = {
    'Content-Type': 'application/json',
    unionId: 0,
    deviceId: 0,
    appPlatform: 2,
    appCode: getCookie('appCode') !== '1' ? 2 : 1, // pos的token对应的appCode是1,PC的token对应的appCode是2,页面内嵌pos时，pos会在cookie里写入appCode=1和pos的token
    appVersion: 0,
    locale: localStorage.getItem('i18n/currentLang') || 'en_US',
  };
  return config;
}

// 响应拦截器
function responseInterceptor (response) {
  const { code, data, msg, message: responseMessage } = response.data;
  switch (code) {
  case 0:
    return data === 'NULL' ? null : response.data;
  case 405:
    handleUnauthorized(msg ?? responseMessage);
    break;
  case 403:
    // Token过期处理
    handleUnauthorized(msg ?? responseMessage);
    break;
  case 605:
    break;
  default:
    message.error(formatBackI18n(msg ?? responseMessage ?? 'Request Failed'));
  }
  return response.data;
}
// 错误拦截器
function errorInterceptor (error) {
  // 显示错误消息
  console.log(error);
  if (error.code !== 'ERR_CANCELED') {
    message.error(`error:${formatBackI18n(error.message)}`);
    // 返回一个默认的响应结构或使用Promise.resolve()，以避免阻塞后续代码的执行
    // 这里返回的结构应根据实际情况和后续代码的需求来定
    return Promise.resolve({
      code: -1, // 自定义错误码
      data: null, // 默认数据
      msg: error.message, // 默认错误消息
    });
  }
}

// 未授权处理
function handleUnauthorized (errorMsg) {
  message.error(`error:${errorMsg}`);
  if (getCookie('appCode') !== '1') {
    window.location.assign(window.location.href.split('/')[0] + '/login');
  } else {
    window.location.assign(window.location.href.split('/')[0] + '/errorPage');
  }
}

const REACT_APP_PEPPR_HOST = process.env.REACT_APP_PEPPR_HOST;
console.log('REACT_APP_PEPPR_HOST', REACT_APP_PEPPR_HOST);

const service = createService(baseURL);
const pepprService = createService(REACT_APP_PEPPR_HOST);
const toResService = createService(REACT_APP_PEPPR_HOST);

export default service;
export { service, toResService, pepprService, REACT_APP_PEPPR_HOST };
