import React, { lazy } from 'react';
import * as Paths from './pathConstants';
import Layout from '@/Components/Layout';
import LayoutPOS from '@/Components/LayoutPOS/index';
import ErrorPage from '@/Pages/ErrorPage';
import Page404 from '@/Pages/Page404';
import Home from '@/Pages/Home';
import Login from '@/Pages/Login';
import OrdersReport from '@/Pages/OrdersReport';
import Permissions from '@/Pages/Permissions';
import Categories from '@/Pages/Categories';
import EmployeeCreate from '@/Pages/EmployeeCreate';
import JobCreate from '@/Pages/JobCreate';
import EmployeeList from '@/Pages/EmployeeList';
import Entrances from '@/Pages/Entrances';
import MyAccount from '@/Pages/MyAccount';
import Roles from '@/Pages/Roles';
import SettleIn from '@/Pages/SettleIn';
import RestaurantInfo from '@/Pages/RestaurantInfo';
import Notes from '@/Pages/Notes';
import UiOptionalConfig from '@/Pages/UiOptionalConfig';
import DiningOptions from '@/Pages/DiningOptions';
import Tables from '@/Pages/Tables';
import SalesSummary from '@/Pages/SalesSummary';
import PayoutReport from '@/Pages/PayoutReport';
import EmployeeTimeSheets from '@/Pages/EmployeeTimeSheets';
// import LaborSummary from '@/Pages/LaborSummary';
import LaborReport from '@/Pages/LaborReport';
import Tips from '@/Pages/Tips';
import Printer from '@/Pages/Printer';
import PosMonitor from '@/Pages/PosMonitor';
import ServiceCharge from '@/Pages/ServiceCharge';
import Transaction  from '@/Pages/Transaction';
import PayoutReportDetail from '@/Pages/PayoutReport/PayoutReportDetail';
import PaymentMethods from '@/Pages/PaymentMethods';
import TaxRates from '@/Pages/TaxRates';
import SalesCategories from '@/Pages/SalesCategories';
import FeeReport from '@/Pages/FeeReport';
import LossReport from '@/Pages/LossReport';
import Promotion from '@/Pages/Promotion';
import ProductMix from '@/Pages/ProductMix';
import CashManagement from '@/Pages/CashManagement';
import MenuDatabase from '@/Pages/Menus/MenuDatabase';
import Menus from '@/Pages/Menus';
import CreateModifier from '@/Pages/Menus/CreateModifier';
import CreateMenu from '@/Pages/Menus/CreateMenu';
import CreateSubMenu from '@/Pages/Menus/CreateSubMenu';
import CreateItem from '@/Pages/Menus/CreateItem';
import DecorationPrintOut from '@/Pages/PrintOutDecoration/DecorationPrintOut';
import OnlineOrders from '@/Pages/OnlineOrders';
import NetSalesSummary from '@/Pages/NetSalesSummary';
import CashDiscountsProgramReport from '@/Pages/CashDiscountsProgramReport';
import CashDrawerOverview from '@/Pages/CashDrawerOverview';
import MenusEvents from '@/Pages/Menus/MenuEvents';
import PreModifier from '@/Pages/PreModifier';
import EditPreModifier from '@/Pages/PreModifier/editPreModifier';
import GuestBook from '@/Pages/GuestBook';
import Integrations from '@/Pages/Integrations';

export const routes = [
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      { path: Paths.HOME, element: <Home /> },
      { path: Paths.PERMISSIONS, element: <Permissions /> },
      { path: Paths.EMPLOYEE_LIST, element: <EmployeeList /> },
      { path: Paths.ENTRANCES, element: <Entrances /> },
      { path: Paths.MY_ACCOUNT, element: <MyAccount /> },
      { path: Paths.RESTAURANT_INFO, element: <RestaurantInfo /> },
      { path: Paths.ROLES, element: <Roles /> },
      { path: Paths.SETTLE_IN, element: <SettleIn /> },
      { path: Paths.CATEGORIES, element: <Categories /> },
      { path: Paths.EMPLOYEE_CREATE, element: <EmployeeCreate /> },
      { path: Paths.JOB_CREATE, element: <JobCreate /> },
      { path: Paths.PAYMENTS_PAYOUT, element: <PayoutReport /> },
      { path: Paths.PAYMENTS_PAYOUT_DETAIL, element: <PayoutReportDetail /> },
      { path: Paths.REPORTS_EMPLOYEE_TIME_SHEETS, element: <EmployeeTimeSheets /> },
      { path: Paths.REPORTS_EMPLOYEE_LABOR_SUMMARY, element: <LaborReport /> },
      { path: Paths.REPORTS_ORDERS, element: <OrdersReport /> },
      { path: Paths.REPORTS_SALES, element: <SalesSummary /> },
      { path: Paths.REPORTS_TRANSACTION, element: <Transaction /> },
      { path: Paths.REPORTS_PRODUCT_MIX, element: <ProductMix /> },
      { path: Paths.REPORTS_CASH_MANAGEMENT, element: <CashManagement /> },
      { path: Paths.REPORTS_CASH_DRAWER_OVERVIEW, element: <CashDrawerOverview /> },
      { path: Paths.REPORTS_LOSS_REPORT, element: <LossReport /> },
      { path: Paths.FEE, element: <FeeReport /> },
      { path: Paths.MENUS_MENU_BUILDER, element: <Menus /> },
      { path: Paths.MENUS_CREATE_MENU, element: <CreateMenu /> },
      { path: Paths.MENUS_CREATE_SUB_MENU, element: <CreateSubMenu /> },
      { path: Paths.MENUS_CREATE_MODIFIER, element: <CreateModifier /> },
      { path: Paths.MENUS_CREATE_ITEM, element: <CreateItem /> },
      { path: Paths.MENUS_MENU_DATABASE, element: <MenuDatabase /> },
      { path: Paths.MENUS_SALES_CATEGORIES, element: <SalesCategories /> },
      { path: Paths.FRONT_OF_HOUSE_NOTES, element: <Notes /> },
      { path: Paths.FRONT_OF_HOUSE_GUEST_DISPLAY, element: <UiOptionalConfig /> },
      { path: Paths.FRONT_OF_HOUSE_TIPS, element: <Tips /> },
      { path: Paths.FRONT_OF_HOUSE_DINING_OPTION, element: <DiningOptions /> },
      { path: Paths.FRONT_OF_HOUSE_TABLES, element: <Tables /> },
      { path: Paths.DEVICE_PRINTER, element: <Printer /> },
      { path: Paths.DECORATION_PRINT_OUT, element: <DecorationPrintOut /> },
      { path: Paths.DEVICE_POS_MONITOR, element: <PosMonitor /> },
      { path: Paths.FRONT_OF_HOUSE_SERVICE_CHARGE, element: <ServiceCharge /> },
      { path: Paths.PAYMENTS_PAYMENT_METHODS, element: <PaymentMethods /> },
      { path: Paths.MENUS_TAX_RATES, element: <TaxRates /> },
      { path: Paths.MENUS_PRE_MODIFIER, element: <PreModifier /> },
      { path: Paths.MENUS_PRE_MODIFIER_EDIT, element: <EditPreModifier /> },
      { path: Paths.PROMOTION, element: <Promotion /> },
      { path: Paths.ONLINE_ORDERS, element: <OnlineOrders /> },
      { path: Paths.NET_SALES_SUMMARY, element: <NetSalesSummary /> },
      { path: Paths.REPORTS_CASH_DISCOUNTS_PROGRAM, element: <CashDiscountsProgramReport /> },
      { path: Paths.MENUS_EVENTS, element: <MenusEvents /> },
      { path: Paths.GUEST_BOOK, element: <GuestBook /> },
      { path: Paths.INTEGRATIONS, element: <Integrations /> },
    ],
  },
  {
    path: '/pos',
    element: <LayoutPOS />,
    errorElement: <ErrorPage />,
    children: [
      { path: '/pos' + Paths.HOME, element: <Home /> },
      { path: '/pos' + Paths.PERMISSIONS, element: <Permissions /> },
      { path: '/pos' + Paths.EMPLOYEE_LIST, element: <EmployeeList /> },
      { path: '/pos' + Paths.ENTRANCES, element: <Entrances /> },
      { path: '/pos' + Paths.MY_ACCOUNT, element: <MyAccount /> },
      { path: '/pos' + Paths.RESTAURANT_INFO, element: <RestaurantInfo /> },
      { path: '/pos' + Paths.ROLES, element: <Roles /> },
      { path: '/pos' + Paths.SETTLE_IN, element: <SettleIn /> },
      { path: '/pos' + Paths.CATEGORIES, element: <Categories /> },
      { path: '/pos' + Paths.EMPLOYEE_CREATE, element: <EmployeeCreate /> },
      { path: '/pos' + Paths.JOB_CREATE, element: <JobCreate /> },
      { path: '/pos' + Paths.PAYMENTS_PAYOUT, element: <PayoutReport /> },
      { path: '/pos' + Paths.PAYMENTS_PAYOUT_DETAIL, element: <PayoutReportDetail /> },
      { path: '/pos' + Paths.REPORTS_EMPLOYEE_TIME_SHEETS, element: <EmployeeTimeSheets /> },
      { path: '/pos' + Paths.REPORTS_EMPLOYEE_LABOR_SUMMARY, element: <LaborReport /> },
      { path: '/pos' + Paths.REPORTS_ORDERS, element: <OrdersReport /> },
      { path: '/pos' + Paths.REPORTS_SALES, element: <SalesSummary /> },
      { path: '/pos' + Paths.REPORTS_TRANSACTION, element: <Transaction /> },
      { path: '/pos' + Paths.REPORTS_PRODUCT_MIX, element: <ProductMix /> },
      { path: '/pos' + Paths.REPORTS_CASH_MANAGEMENT, element: <CashManagement /> },
      { path: '/pos' + Paths.REPORTS_CASH_DRAWER_OVERVIEW, element: <CashDrawerOverview /> },
      { path: '/pos' + Paths.REPORTS_LOSS_REPORT, element: <LossReport /> },
      { path: '/pos' + Paths.FEE, element: <FeeReport /> },
      { path: '/pos' + Paths.MENUS_MENU_BUILDER, element: <Menus /> },
      { path: '/pos' + Paths.MENUS_CREATE_MENU, element: <CreateMenu /> },
      { path: '/pos' + Paths.MENUS_CREATE_SUB_MENU, element: <CreateSubMenu /> },
      { path: '/pos' + Paths.MENUS_CREATE_MODIFIER, element: <CreateModifier /> },
      { path: '/pos' + Paths.MENUS_CREATE_ITEM, element: <CreateItem /> },
      { path: '/pos' + Paths.MENUS_MENU_DATABASE, element: <MenuDatabase /> },
      { path: '/pos' + Paths.MENUS_SALES_CATEGORIES, element: <SalesCategories /> },
      { path: '/pos' + Paths.FRONT_OF_HOUSE_NOTES, element: <Notes /> },
      { path: '/pos' + Paths.FRONT_OF_HOUSE_GUEST_DISPLAY, element: <UiOptionalConfig /> },
      { path: '/pos' + Paths.FRONT_OF_HOUSE_TIPS, element: <Tips /> },
      { path: '/pos' + Paths.FRONT_OF_HOUSE_DINING_OPTION, element: <DiningOptions /> },
      { path: '/pos' + Paths.FRONT_OF_HOUSE_TABLES, element: <Tables /> },
      { path: '/pos' + Paths.DEVICE_PRINTER, element: <Printer /> },
      { path: '/pos' + Paths.DECORATION_PRINT_OUT, element: <DecorationPrintOut /> },
      { path: '/pos' + Paths.DEVICE_POS_MONITOR, element: <PosMonitor /> },
      { path: '/pos' + Paths.FRONT_OF_HOUSE_SERVICE_CHARGE, element: <ServiceCharge /> },
      { path: '/pos' + Paths.PAYMENTS_PAYMENT_METHODS, element: <PaymentMethods /> },
      { path: '/pos' + Paths.MENUS_TAX_RATES, element: <TaxRates /> },
      { path: '/pos' + Paths.MENUS_PRE_MODIFIER, element: <PreModifier /> },
      { path: '/pos' + Paths.MENUS_PRE_MODIFIER_EDIT, element: <EditPreModifier /> },
      { path: '/pos' + Paths.PROMOTION, element: <Promotion /> },
      { path: '/pos' + Paths.ONLINE_ORDERS, element: <OnlineOrders /> },
      { path: '/pos' + Paths.NET_SALES_SUMMARY, element: <NetSalesSummary /> },
      { path: '/pos' + Paths.REPORTS_CASH_DISCOUNTS_PROGRAM, element: <CashDiscountsProgramReport /> },
      { path: '/pos' + Paths.MENUS_EVENTS, element: <MenusEvents /> },
      { path: '/pos' + Paths.GUEST_BOOK, element: <GuestBook /> },
      { path: '/pos' + Paths.INTEGRATIONS, element: <Integrations /> },
    ],
  },
  { path: Paths.LOGIN, element: <Login /> },
  { path: Paths.FORGET_PASSWORD, element: <Login /> },
  { path: Paths.REGISTER, element: <Login /> },
  { path: 'errorPage', element: <ErrorPage /> },
  { path: Paths.PAGE_NOT_FOUND, element: <Page404 /> },
];
