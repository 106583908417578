import { toResService } from '@/request/index';

export const getSignupUrl: any = (params) => toResService.post('/api/peppr-integration/doordash/onboarding/getSignupUrl', params);

// DoorDash登陆链接：https://identity.doordash.com/auth?response_type=code&redirect_uri=https://www.doordash.com/&scope=*&state=test&client_id=1816264082131884040&prompt=none&layout=default
// DoorDash登陆成功后，会重定向回到redirect_uri指定的地址，并在路径params中携带code：https://www.doordash.com/?state=test&code=[CODE_-_UUID]&action=Login&method=[Email]，code字段将作为exchangeCode方法入参
export const exchangeCode: any = (params) => toResService.post('/api/peppr-integration/doordash/onboarding/exchangeCode', params);

/**
  The max limit value supported is 100.
  仅在exchangeCode后1h内有效（实际约59min），授权过期会返回code=60000，Dashboard需要引导用户重新登陆
  用户无权限时返回code=60001，Dashboard需要引导用户重新登陆
  isEligibleToOnboard字段为false时应置灰且不可选
  total=0时需要引导用户signup
*/
export const getDoordashStores: any = (params) => toResService.post('/api/peppr-integration/doordash/onboarding/queryStores', params);

// 仅在exchangeCode后1h内有效（实际约59min），授权过期会返回code=60000，Dashboard需要引导用户重新登陆
export const initOnboarding: any = (params) => toResService.post('/api/peppr-integration/doordash/onboarding/initOnboarding', params);

// 轮询场景forceQuery设为false（查询integration数据库），强制刷新场景设为true（查询DoorDash），不传默认false
export const queryOnboardingStatus: any = (params) => toResService.post('/api/peppr-integration/doordash/onboarding/queryOnboardingStatus', params);

export const triggerMenuPull: any = (params) => toResService.post('/api/peppr-integration/doordash/onboarding/triggerMenuPull', params);

export const activateOnboarding: any = (params) => toResService.post('/api/peppr-integration/doordash/onboarding/activateOnboarding', params);

export const queryAuthStatus: any = (params) => toResService.post('/api/peppr-integration/doordash/onboarding/queryAuthStatus', params);