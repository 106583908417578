import React, { FC, useImperativeHandle, useEffect, useState, forwardRef } from 'react';
import { Typography, Radio, Form, InputNumber } from 'antd'
import { globalState } from '@/stores';
import './Setting.scss';
import { SettingEnum } from './enum';
const { Paragraph } = Typography;
const Setting = forwardRef((props: any, ref) => {
  const { i18n } = globalState;
  const [form] = Form.useForm();
  const [minSelection, setMinSelection] = useState<number>(0);
  const [requiredModeType, setRequiredModeType] = useState<boolean>(false);
  const [multiSelect, setMultiSelect] = useState<boolean>(false);
  const [maxSelect, setMaxSelect] = useState<number>(99);
  const [minInputMinimum, setMinInputMinimum] = useState<number>(1);
  const [minInputMaximum, setMinxInputMaximum] = useState<number>(1);
  useImperativeHandle(ref, () => {
    return {
      form,
      initSetting,
      setMinSelection,
      resetForm
    };
  }, [multiSelect, maxSelect, minInputMinimum, minInputMaximum]);
  useEffect(() => {
    const maxLength = props.maxLength;
    if (multiSelect && maxLength > 0) {
      setMinxInputMaximum(maxLength);
    }
    if (requiredModeType && multiSelect) { // 场景是选中了required并且Multiple selections为yes
      const formMinSelections = form.getFieldValue('minSelections');
      if (maxLength > 0 && formMinSelections > maxLength) {
        form.setFieldValue('minSelections', maxLength)
      }
    }
  }, [props.maxLength])
  const initSetting = (setting, tableLength) => {
    const { requiredModeType, multiSelect: _multiSelect, maxSelections, minSelections, duplicateModifiersEnabled } = setting;
    const type = requiredModeType['value'] === 'REQUIRED';
    setMinSelection(minSelections)
    setRequiredModeType(type);
    setMultiSelect(_multiSelect);
    form.setFieldsValue({
      requiredModeType: type,
      multiSelect: _multiSelect,
      minSelections,
      maxSelections,
      duplicateModifiersEnabled
    })
    if (_multiSelect && tableLength > 0) {
      setMinxInputMaximum(tableLength);
    }
  }
  const resetForm = () => {
    form.resetFields();
    setMinSelection(0);
    setRequiredModeType(false);
    setMultiSelect(false);
    setMaxSelect(99);
    setMinInputMinimum(1);
    setMinxInputMaximum(1);
  }
  const changeMinSelections = (val) => {
    const max = form.getFieldValue('maxSelections');
    setMinSelection(val);
    if (val > max) {
      form.setFieldValue('maxSelections', val);
    }
  }
  const changeMinxInputMaximum = () => {
    const maxLength = props.maxLength;
    const formMinSelections = form.getFieldValue('minSelections');
    if (maxLength > 0 && formMinSelections > maxLength) {
      form.setFieldValue('minSelections', maxLength)
    }
    if (maxLength > 0) {
      setMinxInputMaximum(maxLength);
    }
  }
  const changeRequiredModeType = (e) => {
    const type = e.target.value
    changeMinxInputMaximum();
    form.setFieldValue('minSelections', type ? 1 : 0);
    setMinInputMinimum(type ? 1 : 0);
    setRequiredModeType(type);
  }
  const changeMultiSelect = (e) => {
    changeMinxInputMaximum();
    setMultiSelect(e.target.value);
  }
  return <div className='create-modifier-setting'>
    <Paragraph strong>{i18n.t('settings')}</Paragraph>
    <Form
      form={form}
      name="general_info_form"
      layout="vertical"
      initialValues={{
        requiredModeType: false,
        multiSelect: false,
        minSelections: 1,
        duplicateModifiersEnabled: false
      }}
    >
      <Form.Item name="requiredModeType" label={i18n?.t('createModifierGroup_pc_select_a')}>
        <Radio.Group onChange={e => changeRequiredModeType(e)}>
          <Radio value={true}>{i18n.t('rms_overseas_operation_configuration_frontofhouse_ChooseType_REQUIRED')}</Radio>
          <Radio value={false}>{i18n.t('rms_overseas_operation_configuration_frontofhouse_ChooseType_OPTIONAL')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="multiSelect" label={i18n?.t('menu_multiple_selections')}>
        <Radio.Group onChange={e => changeMultiSelect(e)}>
          <Radio value={true}>{i18n?.t('Dashboard_PrintConfig_GroupbyItem_Yes')}</Radio>
          <Radio value={false}>{i18n?.t('Dashboard_PrintConfig_GroupbyItem_No')}</Radio>
        </Radio.Group>
      </Form.Item>
      {
        multiSelect && <>
          <Form.Item name="minSelections" label="Lower selection limit" style={{ 'width': '300px' }}>
            <InputNumber disabled={!requiredModeType} style={{ 'width': '100%' }} min={requiredModeType === false ? 0 : minInputMinimum} max={minInputMaximum} onChange={changeMinSelections}/>
          </Form.Item>
          <Form.Item name="maxSelections" label="Max selection limit" style={{ 'width': '300px' }}>
            <InputNumber style={{ 'width': '100%' }} min={minSelection} max={maxSelect}/>
          </Form.Item>
          <Form.Item name="duplicateModifiersEnabled" label='Select a modifier more than once'>
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </>
      }
    </Form>
  </div>
})

export default Setting;