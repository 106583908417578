import React, { useEffect, useState } from 'react';
import './index.scss';
import { globalState } from '@/stores';
import { Input, Table, Image, message } from 'antd';
import useGetState from '@/hooks/useGetState';
import { removeNullUndefinedFields, formatFullPrice } from '@/utils';
import { TableEmptyContent } from '@/Components/EmptyContent';
import { ColumnsType } from 'antd/es/table';
import { EReport_Status, IPage } from '@/types';
import { IGuestTableRow } from './interface';
import { SearchOutlined } from '@ant-design/icons';
import { ApiExportGuestBookList, ApiQueryGuestBookList } from '@/request/api';
import logoPng from '@/assets/logo-collapse.png';
import { debounce } from '@/utils/index';
import PepprExportComponent from '@/Components/Export/PepprExportComponent';
import { IDropMenuExportParams, IExportResult } from '@/Components/Export/interface';
import axios from 'axios';

// TODO: axios 的请求可以统一转移到 request 文件夹下
let axiosSource = axios.CancelToken.source();

export default function GuestBook () {
  const { i18n } = globalState;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState<Array<IGuestTableRow>>([]);
  const [sortedInfo, setSortedInfo, getSortedInfo] = useGetState({
    columnKey: 'lastOrderTime',
    order: 'descend',
  });
  const [searchValue, setSearchValue] = useState<string>();
  const [pager, setPager, getPager] = useGetState<IPage>({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  });
  const tableColumns = [
    {
      dataIndex: 'guestId',
      key: 'guestId',
      title: i18n.t('pc_guestbook_contact'),
      width: 250,
      render: (val, record) => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <div>{record.fullName}</div>
              <div>{record.email} {record.phone}</div>
            </div>
            {record.isPepprAccount && (
              <Image
                src={logoPng}
                preview={false}
                style={{ width: 14, height: 14 }}
              ></Image>
            )}
          </div>
        );
      },
    },
    {
      dataIndex: 'lastOrderTime',
      key: 'lastOrderTime',
      title: i18n.t('pc_guestbook_lastOrder'),
      width: 250,
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === 'lastOrderTime' ? sortedInfo.order : null,
      sortDirections: ['descend', 'ascend', 'descend'],
      render: (val, record) => {
        return <div>{record.lastOrderTime || '-'}  {record.diningOptionName}</div>;
      },
    },
    {
      dataIndex: 'numberOfOrder',
      key: 'numberOfOrder',
      title: i18n.t('pc_guestbook_numberOfOrders'),
      width: 150,
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === 'numberOfOrder' ? sortedInfo.order : null,
      sortDirections: ['descend', 'ascend', 'descend'],
    },
    {
      dataIndex: 'avgSpend',
      key: 'avgSpend',
      title: i18n.t('pc_guestbook_avgSpend'),
      width: 150,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'avgSpend' ? sortedInfo.order : null,
      sortDirections: ['descend', 'ascend', 'descend'],
      render: (val) => formatFullPrice(val),
    },
    {
      dataIndex: 'lifetimeSpend',
      key: 'lifetimeSpend',
      title: i18n.t('pc_guestbook_lifetimeSpend'),
      width: 150,
      render: (val) => formatFullPrice(val),
    },
  ];

  const fetchTableData = async () => {
    const _pager = getPager();
    const _params = getRequestParams();
    setIsLoading(true);
    let res;
    axiosSource.cancel();
    axiosSource = axios.CancelToken.source();
    try {
      res = await ApiQueryGuestBookList(removeNullUndefinedFields(_params), { cancelToken: axiosSource.token });
    } catch (err) {
      if (axios.isCancel(err)) return;
      message.error(err.message);
    }
    setIsLoading(false);
    if (res.code !== 0) return;
    const { list, total, current } = res?.data || {};
    setTableData(list || []);
    setPager({ ..._pager, current, total });
  };

  const handleTableChange = (_pagination, _, sorter) => {
    setPager({ ...pager, ..._pagination });
    setSortedInfo(sorter);
    fetchTableData();
  };

  const getRequestParams = () => {
    const { columnKey, order } = getSortedInfo();
    const { current, pageSize } = getPager();
    return {
      orderBy: {
        field: columnKey,
        order: order === 'ascend' ? 'ASC' : 'DESC',
      },
      current,
      pageSize,
      request: {
        keyWord: searchValue || undefined,
      },
    };
  };


  // 导出相关
  const fetchApiExport = async (exportType: IDropMenuExportParams) => {
    const params = {
      exportType: EReport_Status[exportType],
    }
    const res: any = await ApiExportGuestBookList(removeNullUndefinedFields(params));

    const exportResult: IExportResult = {
      exportStatus: res.code === 0,
      exportId: res?.data?.exportTaskId ?? null,
      exportFailErrorMsg: res,
    }

    return exportResult
  }


  useEffect(() => {
    setPager((prevPager) => ({ ...prevPager, current: 1 }));
    debounce(fetchTableData(), 500);
  }, [searchValue]);

  return (
    <div className="cash-discounts-program-wrap">
      <div className="m-title">
        <div className="title">{i18n.t('pc_guestbook_title')}</div>
      </div>
      <div className="filter-wrap">
        <div className="left-wrap">
          <Input
            allowClear
            placeholder={i18n.t('pc_guestbook_search')}
            prefix={<SearchOutlined />}
            style={{ width: 300 }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <div style={{ fontSize: 18, fontWeight: 600, margin: '16px 0px' }}>
            {pager?.total || 0} {i18n.t('pc_guestbook_Totalguest')}
          </div>
        </div>
      </div>
      <div className="table-wrap">
        <Table
          columns={tableColumns as ColumnsType}
          dataSource={tableData}
          rowKey={'guestId'}
          loading={isLoading}
          pagination={pager}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
          locale={{ emptyText: TableEmptyContent }}
        />
      </div>

      <div className="action-wrap">
        <PepprExportComponent
          type='button'
          isDropDown={true}
          disabled={tableData.length === 0}
          fetchApiExport={(exportType) => fetchApiExport(exportType)}
        />
      </div>
    </div>
  );
}
