import React from 'react';
import { Form, InputNumber, Select, Button, message } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import TimeRangePicker from '@/Components/TimeRangePicker';
import { globalState } from '@/stores'; // 引入全局状态和 i18n
import './Availability.scss';
import { dayOptions } from '../enum'

interface PriceDetail {
  startTime?: number;
  endTime?: number;
  days: number[];
  timeRange?: [number, number];
  value: string | number;
}

interface TimeBasedPriceFormProps {
  form: any;
  value?: PriceDetail[];
  style?: React.CSSProperties;
  onChange?: (data: PriceDetail[]) => void;
}

const TimeBasedPriceForm: React.FC<TimeBasedPriceFormProps> = ({
  value = [],
  onChange,
  style,
  form,
}) => {
  const { i18n } = globalState;

  // 通知表单变更
  const notifyChange = (updatedPeriods: PriceDetail[]) => {
    if (typeof onChange === 'function') {
      onChange(updatedPeriods);
    }
  };

  // 检查时间段是否有重叠
  const hasOverlappingPeriods = (periods: PriceDetail[]): boolean => {
    const dayMap: Record<number, { startTime: number; endTime: number }[]> = {};

    periods.forEach(({ startTime, endTime, days }) => {
      days.forEach((day) => {
        if (!dayMap[day]) {
          dayMap[day] = [];
        }
        dayMap[day].push({ startTime, endTime });
      });
    });

    for (const day in dayMap) {
      const timePeriods = dayMap[day];
      timePeriods.sort((a, b) => a.startTime - b.startTime);

      for (let i = 1; i < timePeriods.length; i++) {
        const prev = timePeriods[i - 1];
        const curr = timePeriods[i];
        if (curr.startTime < prev.endTime) {
          return true; // 存在时间重叠
        }
      }
    }

    return false; // 无重叠
  };

  // 提交表单
  const handleSubmit = () => {
    form
      .validateFields()
      .then(() => {
        const formattedData = form.getFieldValue('priceDetails').map((item: any) => ({
          startTime: item.timeRange[0],
          endTime: item.timeRange[1],
          days: item.days,
          value: item.value,
        }));

        if (hasOverlappingPeriods(formattedData)) {
          return message.error(i18n?.t('timeOverlapError') || 'Time periods have overlapping days.');
        }

        console.log('Submitted Data:', formattedData); // 提交时可传递给后端
        notifyChange(formattedData);
      })
      .catch((error) => {
        console.error('Validation Failed:', error);
      });
  };

  return (
    <div className='availability-price' style={style}>
      <Form
        layout='vertical'
        form={form}
        style={{ display: 'grid', gap: '16px' }}
        name='priceConfig'
        // initialValues={{ priceDetails: value }}
        initialValues={{ priceDetails: [{ timeRange: null, days: [], value: null }] }}

      >
        <Form.List name='priceDetails'>
          {(fields, { add, remove }) => {
            console.log(fields)
            if (!fields.length) return <Button
              style={{ width: '211px' }}
              onClick={() => add({
                value: null, // 初始价格
                timeRange: null, // 初始时间范围
                days: [], // 初始日期
              })}>{i18n.t('Dashboad_Menu_NewTBP')}</Button>;
            return (
              <>
                <div className='table-header'>
                  <div >{i18n?.t('price') || 'Price'}<span style={{ color: 'red' }}>*</span></div>
                  <div >{i18n?.t('pc_menu_hours') || 'Hours'}<span style={{ color: 'red' }}>*</span></div>
                  <div >{i18n?.t('pc_availability_title') || 'Days'}<span style={{ color: 'red' }}>*</span></div>
                  <div />
                </div>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} className='table-body'>
                    <Form.Item
                      {...restField}
                      name={[name, 'value']}
                      rules={[{ required: true, message: '' }]}
                    >
                      <InputNumber prefix='$' min={0} max={999999} precision={2} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'timeRange']}
                      rules={[{ required: true, message: '' }]}
                    >
                      <TimeRangePicker use12Hours endTimeSet={true} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'days']}
                      rules={[{ required: true, message: '' }]}
                    >
                      <Select
                        mode='multiple'
                        maxTagCount='responsive'
                        placeholder={i18n?.t('days') || 'Days'}
                        allowClear
                        options={dayOptions}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                    <Button type='link' icon={<CloseCircleOutlined/>} onClick={() => remove(name)} ></Button>
                  </div>
                ))}
                {fields.length < 20 && <Form.Item>
                  <Button type='link' onClick={() => add({
                    value: null, // 初始价格
                    timeRange: null, // 初始时间范围
                    days: [], // 初始日期
                  })} >
                    + {i18n?.t('add_new')}
                  </Button>
                </Form.Item>}
              </>
            )
          }
          }
        </Form.List>
      </Form>
    </div>
  );
};

export default TimeBasedPriceForm;
