import { globalState } from '@/stores';
import React, { useEffect, useState } from 'react';
import './index.scss'
import PepprDatePicker, { DATE_RANGE_TYPE } from '@/Components/DatePicker';
import { Button, Select, Table, Tooltip } from 'antd';
import useGetState from '@/hooks/useGetState';
import dayjs, { Dayjs } from 'dayjs';
import { getToday, listSortBy, removeNullUndefinedFields, toRes, formatTimestampWithoutTimeZone } from '@/utils';
import { ApiExportCashDrawerTransDetail, ApiExportTimesheet, ApiQueryEmployeeList, ApiQueryTimesheet } from '@/request/api';
import { TableEmptyContent } from '@/Components/EmptyContent';
import { ColumnsType } from 'antd/es/table';
import Columns from '@/Components/Columns/Columns';
import AddTimeSheet, { TimeSheetType } from './component/addTimeSheet';
import { InfoCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { EReport_Status, RangeValueType } from '@/types';
import PepprExportComponent from '@/Components/Export/PepprExportComponent';
import { IDropMenuExportParams, IExportResult } from '@/Components/Export/interface';
import { getCookie } from '@/utils';

interface IPage {
  current: number;
  pageSize: number;
  total: number;
  showSizeChanger: boolean;
  showQuickJumper: boolean;
  showTotal: (total: number) => string;
}

enum SORT_FIELD {
  firstName = 1,
  lastName = 2,
  clockIn = 3,
  clockOut = 4,
  hours = 5
}

interface IParamsState {
  date: RangeValueType,
  employeeIdList: Array<number>,
  sortByDescTime: boolean,
  dateRangeType: string
}


export default function EmployeeTimeSheets () {
  const { i18n } = globalState;

  const [isInit, setIsInit] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [today, setToday] = useState<Dayjs>(dayjs())
  const [rangePresets, setRangePresets] = useState([])
  const [employeeAllList, setEmployeeAllList] = useState([])
  const [isShowEmptyTable, setIsShowEmptyTable] = useState<boolean>(false)
  const [tableData, setTableData] = useState([])
  const [sortedInfo, setSortedInfo, getSortedInfo] = useGetState({ columnKey: 'standardClockIn', order: 'descend' });
  const [showColumnsList, setShowColumnsList] = useState([])
  const [pager, setPager, getPager] = useGetState<IPage>({
    current: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  })
  const [params, setParams, getParams] = useGetState<IParamsState>({
    date: [dayjs(), dayjs()],
    employeeIdList: [],
    sortByDescTime: false,
    dateRangeType: DATE_RANGE_TYPE.TODAY
  })
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [timeSheet, setTimeSheet] = useState<TimeSheetType>();
  const tableColumns = [
    {
      dataIndex: 'firstName', key: 'firstName', title: i18n.t('timesheets_pc_first_name'), width: 150,
    },
    {
      dataIndex: 'lastName', key: 'lastName', title: i18n.t('timesheets_pc_last_name'), width: 150,
    },
    {
      dataIndex: 'roleName', key: 'roleName', title: i18n.t('dashboard_report_labor_summary_role'), width: 150,
    },
    {
      dataIndex: 'standardClockIn', key: 'standardClockIn', title: i18n.t('timesheets_pc_clock_in'), width: 200,
      sorter: true, sortOrder: sortedInfo.columnKey === 'standardClockIn' ? sortedInfo.order : null, sortDirections: ['descend', 'ascend', 'descend'],
      render: (val) => formatTimestampWithoutTimeZone(val)
    },
    {
      dataIndex: 'standardClockOut', key: 'standardClockOut', title: i18n.t('timesheets_pc_clock_out'), width: 200,
      sorter: true, sortOrder: sortedInfo.columnKey === 'standardClockOut' ? sortedInfo.order : null, sortDirections: ['descend', 'ascend', 'descend'],
      render: (val) => !!val ? formatTimestampWithoutTimeZone(val) : 'N/A'
    },
    {
      dataIndex: 'hours',
      key: 'hours',
      title: <div>
        {i18n.t('timesheets_pc_total_hours')}
        <Tooltip title={i18n.t('dashboard_employee_timesheet_total_hours_note')}>
          <InfoCircleOutlined style={{ marginLeft: 10 }} />
        </Tooltip>
      </div>,
      width: 150,
      render: (val) => `${ val.toFixed(2) } hrs`
    },
    {
      dataIndex: 'breakPaidHours',
      key: 'breakPaidHours',
      title: i18n.t('dashboard_team_breaks'),
      width: 150,
      render: (val, record) => (
        <div>
          {record.breakPaidHours && <div>{i18n.t('rms_overseas_transaction_order_OrderPayStatus_PAID')}  - {record.breakPaidHours} hrs</div>}
          {record.breakUnpaidHours && <div>{i18n.t('rms_overseas_transaction_order_OrderPayStatus_UNPAID')} - {record.breakUnpaidHours} hrs</div>}
        </div>
      ),
    },
  ]

  useEffect(() => {
    initPage();
  }, []);

  const initPage = async () => {
    setShowColumnsList(tableColumns.map(x => x.dataIndex))
    fetchEmployeeList();
    const _today = await getToday();
    setToday(_today);
    setParams({ ...params, date: [_today, _today] });
    await fetchTableData();
    setIsInit(true);
  }

  const fetchTableData = async () => {
    const _pager = getPager()
    const _sorter = getSortedInfo();
    const { date, ...curParams } = getParams();
    console.log(_sorter.columnKey, SORT_FIELD[_sorter.columnKey], _sorter)
    const reqParams = {
      current: _pager.current,
      pageSize: _pager.pageSize,
      request: {
        dateRangeType: curParams.dateRangeType,
        beginTime: date[0].format('YYYY-MM-DD 00:00:00'),
        endTime: date[1].format('YYYY-MM-DD 23:59:59'),
        employeeIdList: curParams.employeeIdList?.length === 0 ? null : curParams.employeeIdList,
      },
      orderBy: {
        field: _sorter.columnKey === 'standardClockIn' ? 'clock_in_time' : _sorter.columnKey === 'standardClockOut' ? 'clock_out_time' : '',
        order: _sorter.order === 'ascend' ? 'ASC' : 'DESC'
      },
    }
    setIsLoading(true);
    const res = await ApiQueryTimesheet(removeNullUndefinedFields(reqParams))
    setIsLoading(false);
    if (res.code !== 0) return;
    const { list = [], current, total } = res.data;
    setTableData(list)
    setPager({ ..._pager, current, total })
  }

  const shouldTableData = () => {
    const _sorter = getSortedInfo();
    if (['firstName', 'lastName', 'hours'].includes(_sorter.columnKey)) {
      return listSortBy(tableData, [_sorter.columnKey], _sorter.order === 'ascend');
    }
    return tableData;
  }

  const fetchEmployeeList = async () => {
    const _params = { needPage: false }
    const res = await ApiQueryEmployeeList(_params)
    if (res.code !== 0) return;
    const { list = [] } = res.data;
    setEmployeeAllList(list.map(x => ({ label: x.employee.fullName, value: x.employee.employeeId })))
  }

  const handleEmployeeChange = (val) => {
    setParams({ ...params, employeeIdList: val ? [val] : undefined });
    setPager({ ...pager, current: 1 })
    fetchTableData();
  }

  const onDateRangeChange = (dates) => {
    const dateRangeType = rangePresets.find((v) => {
      return (v.value[0].isSame(dates[0])) && (v.value[1].isSame(dates[1]))
    })?.key ?? DATE_RANGE_TYPE.CUSTOM
    setParams(({ ...params, date: dates, dateRangeType }))
    fetchTableData();
  };

  const handleTableChange = (_pagination, _, sorter) => {
    setPager({ ...pager, ..._pagination });
    setSortedInfo(sorter)
    fetchTableData();
  }

  const handleChangeColumns = (list) => {
    setIsShowEmptyTable(list.length === 0)
    setShowColumnsList(list);
  }

  const handleAddTimeSheet = () => {
    setTimeSheet(null);
    setOpenModal(true);
  }


  // 导出相关
  const fetchApiExport = async (exportType: IDropMenuExportParams) => {
    const params = {
      ...getRequestParams(),
      exportType: EReport_Status[exportType],
    }
    const res: any = await ApiExportTimesheet(removeNullUndefinedFields(params));

    const exportResult: IExportResult = {
      exportStatus: res.code === 0,
      exportId: res?.data?.exportTaskId ?? null,
      exportFailErrorMsg: res,
    }

    return exportResult
  }


  const getRequestParams = () => {
    const { date } = getParams();
    return {
      beginTime: date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: date[1].format('YYYY-MM-DD 23:59:59'),
    }
  }


  return (
    <div className="time-sheets-wrap">
      {getCookie('appCode') !== '1' && <div className="m-title">
        <div className="title"></div>
        <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <PepprExportComponent
            type='button'
            isDropDown={true}
            disabled={tableData.length === 0}
            fetchApiExport={(exportType) => fetchApiExport(exportType)}
          />
          <Button
            type='primary'
            onClick={ handleAddTimeSheet }
            style={
              { marginLeft: 20 }
            }
          >
            {i18n?.t('sales_categories_add_x', { msg: i18n?.t('time_sheet') })}
          </Button>
        </div>
      </div>}
      <div className="filter-wrap">
        <div className="left-wrap">
          <PepprDatePicker value={ params.date } onChange={ onDateRangeChange } today={ today } setPresets={ setRangePresets }/>
          <Select
            className="employee-select"
            showSearch
            optionFilterProp="label"
            options={ employeeAllList }
            value={ params.employeeIdList }
            onChange={ handleEmployeeChange }
            style={ { width: 200 } }
            allowClear
            placeholder={ i18n.t('pc_employee_report_all_employee') }
          />
        </div>
        <div className="right-wrap">
          <Button type="link" onClick={ fetchTableData }><SyncOutlined/></Button>
          <Columns value={ showColumnsList } options={ tableColumns.map(item => ({ label: item.title, value: item.dataIndex })) } onChange={ handleChangeColumns }/>
        </div>
      </div>
      <div className="table-wrap">
        <Table
          className={ isShowEmptyTable && 'empty-table' }
          columns={ tableColumns.filter(x => showColumnsList.includes(x.dataIndex)) as ColumnsType }
          dataSource={ shouldTableData().map((x, i) => ({ ...x, key: i })) }
          rowKey={ 'key' }
          loading={ isLoading }
          pagination={ pager }
          onChange={ handleTableChange }
          scroll={ { x: 'max-content' } }
          locale={ { emptyText: TableEmptyContent } }
          summary={ () => (
            isShowEmptyTable && <Table.Summary.Row>
              <Table.Summary.Cell index={ 0 } colSpan={ 999 }>
                { TableEmptyContent }
              </Table.Summary.Cell>
            </Table.Summary.Row>
          ) }
          onRow={ (record: TimeSheetType) => {
            return {
              onClick: (event: any) => {
                if (getCookie('appCode') === '1') return
                setTimeSheet(record);
                setOpenModal(true);
              }
            }
          } }
        />
      </div>
      <AddTimeSheet
        openModal={openModal}
        setOpenModal={setOpenModal}
        timeSheetInfo={timeSheet}
        handleCancel={() => {setTimeSheet(null)}}
        handleOk={() => {fetchTableData()}}
      />
    </div>
  )
}