/**
 * @description 取件时段组件
 **/
import React from 'react';
import { Form, Radio } from 'antd';
import { globalState } from '@/stores';
import { WEEK_DAY_I18N, WEEK_DAY } from '@/Pages/OnlineOrders/interface';
import './pickupHours.scss'
const { Item, List } = Form;
import IntervalTimeGroup from './IntervalTimeGroup';

type IProps = {
  title?: string
  formKey?: string
}
const PickupHours = (props: IProps) => {
  const { title = '', formKey = '' } = props
  const { i18n } = globalState
  const PickUpRadioOptions = [{ value: true, label: i18n.t('pc_online_orders_pickup_hours_open') }, { value: false, label: i18n.t('pc_online_orders_pickup_hours_closed') }]

  const PickupDay = (props: { value?: WEEK_DAY }) => {
    const { value } = props
    return <span className="week-day">{ i18n.t(WEEK_DAY_I18N[value]) }</span>

  }
  return (
    <Item label={ <span className="pickup-hours-label">{ title ? title : i18n.t('pc_online_orders_pickup_hours') }</span>}>
      <div className='pickup-hours-item'>
        <List name={formKey ? formKey : 'pickup_hours'}>
          {(fields) =>
            fields.map((field) => (
              <React.Fragment key={field.key} >
                <Item name={[field.name, 'day']} style={{ padding: '0.5em' }}>
                  <PickupDay />
                </Item>
                <Item name={[field.name, 'openSwitch']} style={{ padding: '0.5em' }}>
                  <Radio.Group options={ PickUpRadioOptions } />
                </Item>
                <div className="interval-times-group">
                  <List name={[field.name, 'intervalTimes']} >
                    {(timeFields, { add, remove }) => (
                      <IntervalTimeGroup add={add} remove={remove} currentDay={field.key} timeFields={timeFields} formKey={formKey} />
                    )}
                  </List>
                </div>

              </React.Fragment>
            ))
          }
        </List>
      </div>

    </Item>

  );
};

export default PickupHours;