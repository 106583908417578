import React, { useEffect, useState } from 'react';
import { Tabs, Result } from 'antd'
import { globalState } from '@/stores';
import './index.scss'
import { TAB_TYPE } from './enum'
import Pickup from './Pickup'
import ThirdPartyOrdering from './ThirdPartyOrdering'
import store from '@/stores/userInfo';
import { checkPermission } from '@/utils/permissionUtils';
import Page403 from '@/Components/Page403';
import { reaction } from 'mobx';
export default function OnlineOrders () {
  const { i18n } = globalState;
  const [tabValue, setTabValue] = useState(TAB_TYPE.PICKUP)
  const [tabsItems, setTabsItems] = useState(null)
  const getTabsItems = () => {
    const items = [];
    if (checkPermission('ONLINE_PICK_UP')) {
      items.push({
        key: TAB_TYPE.PICKUP,
        label: i18n.t('pc_online_orders_pickup'),
        children: <Pickup isActive={ tabValue === TAB_TYPE.PICKUP }/>,
      });
    }

    if (checkPermission('ONLINE_DOORDASH')) {
      items.push({
        key: TAB_TYPE.THIRD_PARTY_ORDERING,
        label: i18n.t('dashboard_3po'),
        children: <ThirdPartyOrdering isActive={ tabValue === TAB_TYPE.THIRD_PARTY_ORDERING }/>,
      });
    }

    return items;
  }
  useEffect(() => {
    // 如果当前选中的tab不在可用的tabs中，则选择第一个可用的tab
    if (tabsItems && tabsItems.length > 0 && !tabsItems.some(item => item.key === tabValue)) {
      setTabValue(tabsItems[0].key);
    }
  }, [tabsItems, tabValue]);
  useEffect(() => {
    const disposer = reaction(
      () => store.getPermissionIds,
      () => {
        const items = getTabsItems();
        console.log('items', items);
        setTabsItems(items);
        console.log('ONLINE_PICK_UP permission:', checkPermission('ONLINE_PICK_UP'));
        console.log('ONLINE_DOORDASH permission:', checkPermission('ONLINE_DOORDASH'));
      },
      { fireImmediately: true } // 这将使反应立即触发一次
    );
    return () => disposer();
  }, [tabValue]);
  const changeTab = (value) => {
    setTabValue(value);
  }

  const tabBarExtraContent = {
    left: <div className="main-title">{ i18n.t('pc_online_orders_title') }</div>
  }

  return (
    <div className="online-orders-wrap">
      {tabsItems !== null && (
        getTabsItems().length > 0 ? (
          <Tabs
            tabBarExtraContent={tabBarExtraContent}
            onChange={changeTab}
            activeKey={tabValue}
            items={getTabsItems()}
          />
        ) : (
          <Page403 />
        )
      )}
    </div>
  )
}