import React, { useEffect, useRef, useState } from 'react'
import { globalState } from '@/stores';
import './index.scss'
import { Button, Form, Spin } from 'antd';
import { getTimeZone, getToday } from '@/utils';
import dayjs, { Dayjs } from 'dayjs';
import { SyncOutlined } from '@ant-design/icons';
import PepprDatePicker, { DATE_RANGE_TYPE } from '@/Components/DatePicker';
import { RangeValueType } from '@/types';
import ComparisonSummary from '@/Pages/NetSalesSummary/ComparisonSummary';
import NetSalesByDate from '@/Pages/NetSalesSummary/NetSalesByDate';
import NetSalesByDaysOfWeek from '@/Pages/NetSalesSummary/NetSalesByDaysOfWeek';
import { ApiQuerySalesCompareReport } from '@/request/api';
import { DATA_TYPE, ISalesCompareReportData, ISalesCompareReportRes } from './interface';
import ReactToPrint from 'react-to-print';

interface IFormParams {
  dateFirst: RangeValueType;
  dateSecond: RangeValueType;
}

export default function NetSalesSummary () {
  const { i18n } = globalState;
  const printRef = useRef(null);

  const [loading, setLoading] = useState<boolean>(true)
  const [today, setToday] = useState<Dayjs>(dayjs())
  const [rangePresets, setRangePresets] = useState([])
  const [salesList, setSalesList] = useState([])
  const [netSalesByDayList, setNetSalesByDayList] = useState([])
  const backupDates = useRef<RangeValueType>();

  const [form] = Form.useForm<IFormParams>()
  const dateFirstWatched = Form.useWatch('dateFirst', form);
  const dateSecondWatched = Form.useWatch('dateSecond', form);

  const formInitialValues = {
    dateFirst: [today, today],
    dateSecond: [today.subtract(7, 'day'), today.subtract(7, 'day')]
  }

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const _today = await getToday();
    const yesterday = _today.subtract(1, 'day');
    setToday(_today);
    form.setFieldsValue({
      dateFirst: [yesterday, yesterday],
      dateSecond: [yesterday.subtract(7, 'day'), yesterday.subtract(7, 'day')]
    });
    setRangePresets([
      { key: DATE_RANGE_TYPE.TODAY, label: i18n.t('pc_employee_report_today'), value: [_today, _today] },
      { key: DATE_RANGE_TYPE.YESTERDAY, label: i18n.t('pc_employee_report_yesterday'), value: [_today.subtract(1, 'day'), _today.subtract(1, 'day')] },
      { key: DATE_RANGE_TYPE.THIS_WEEK, label: i18n.t('pc_employee_report_this_week'), value: [_today.startOf('week'), _today.endOf('week').startOf('day')] },
      { key: DATE_RANGE_TYPE.LAST_WEEK, label: i18n.t('pc_employee_report_last_week'), value: [_today.subtract(1, 'week').startOf('week'), _today.subtract(1, 'week').endOf('week').startOf('day')] },
    ])
    fetchData();
  }

  const handleFormValuesChange = (_params) => {
    fetchData();
  }

  const disabledDate = (current, { from }: any) => {
    if (from) {
      const weekStart = from.startOf('week');
      const weekEnd = from.endOf('week');
      return !(current.isBetween(weekStart, weekEnd) || current.isSame(weekStart));
    }
    return false;
  };

  const fetchData = async () => {
    setLoading(true);
    const dateFirst = form.getFieldValue('dateFirst');
    const _params = {
      beginTime: dateFirst[0].format('YYYY-MM-DD 00:00:00'),
      endTime: dateFirst[1].format('YYYY-MM-DD 23:59:59')
    }
    const res: ISalesCompareReportRes = await ApiQuerySalesCompareReport(_params)
    setLoading(false);
    if (res.code === 0) {
      backupDates.current = form.getFieldValue('dateFirst');
      const dateFirst = form.getFieldValue('dateFirst');
      form.setFieldValue('dateSecond', [dateFirst[0].subtract(1, 'week'), dateFirst[1].subtract(1, 'week')])
      formatDisplayData(res?.data || {})
    } else {
      form.setFieldValue('dateFirst', backupDates.current)
    }
  }

  const formatDisplayData = (_data: Partial<ISalesCompareReportData>) => {
    const { netSalesByDayList: _netSalesByDayList = [], salesList: _salesList = [] } = _data;
    // 处理Sales表格数据
    const resultSalesList = [];
    const currentRowData = _salesList.find(x => x.dataType === DATA_TYPE.CURRENT)
    const compareRowData = _salesList.find(x => x.dataType === DATA_TYPE.COMPARE)
    if (currentRowData) {
      currentRowData.incrementData = _salesList.find(x => x.dataType === DATA_TYPE.INCREMENT);
      resultSalesList.push(currentRowData)
    }
    if (compareRowData) {
      resultSalesList.push(compareRowData)
    }
    setSalesList(resultSalesList)

    // 处理图表数据
    const _netSalesByDayDayjsList = _netSalesByDayList.filter(x => x.date).map(x => {
      let _date: Dayjs;
      if (x.dataType === DATA_TYPE.CURRENT) {
        _date = dayjs(x.date).startOf('day');
      } else if (x.dataType === DATA_TYPE.COMPARE) {
        _date = dayjs(x.date).add(1, 'week').startOf('day');
      }
      return { ...x, date: _date }
    });
    // 生成日期区间数组
    let _dateStart = form.getFieldValue('dateFirst')[0]
    const _dateEnd = form.getFieldValue('dateFirst')[1]
    const dateRange = [_dateStart.startOf('day')];
    while (_dateStart.isBefore(_dateEnd)) {
      dateRange.push(_dateStart.add(1, 'day'));
      _dateStart = _dateStart.add(1, 'day');
    }
    const resultNetSalesByDayDayjsList = []
    dateRange.map(dateItem => {
      // 手动填补缺失的日数据
      const currentItem = _netSalesByDayDayjsList.find(x => x.date.isSame(dateItem, 'day') && x.dataType === DATA_TYPE.CURRENT);
      const compareItem = _netSalesByDayDayjsList.find(x => x.date.isSame(dateItem, 'day') && x.dataType === DATA_TYPE.COMPARE);
      if (currentItem || compareItem) {
        resultNetSalesByDayDayjsList.push({ date: dateItem, currentNetSales: Number(currentItem?.netSales ?? '0'), compareNetSales: Number(compareItem?.netSales ?? '0') })
      }
    })
    setNetSalesByDayList(resultNetSalesByDayDayjsList);
  }

  return (
    <div className="net-sales-summary-report print-container" ref={ printRef }>
      <Spin size="large" spinning={ loading }>
        <div className="m-title">
          <div className="title">{ i18n.t('pc_net_sales_summary_title') }</div>
        </div>
        <div className="content-wrap">
          <div className="filter-wrap">
            <div className="filter-left-wrap">
              <Form form={ form } layout="inline" onValuesChange={ handleFormValuesChange } initialValues={ formInitialValues }>
                <Form.Item name={ 'dateFirst' } label={ i18n.t('pc_net_sales_summary_current') }>
                  <PepprDatePicker today={ today } presets={ rangePresets } disabledDate={ disabledDate }/>
                </Form.Item>
                <Form.Item name={ 'dateSecond' } label={ i18n.t('pc_net_sales_summary_compared_to') }>
                  <PepprDatePicker today={ today } disabled/>
                </Form.Item>
              </Form>
            </div>
            <div className="filter-right-wrap">
              <Button icon={ <SyncOutlined/> } onClick={ fetchData }/>
              <ReactToPrint
                trigger={ () => <Button icon={ <div className="iconfont icon-print"/> }/> }
                content={ () => printRef.current }
              />
            </div>
          </div>
          <ComparisonSummary dateFirst={ dateFirstWatched } dateSecond={ dateSecondWatched } dataList={ salesList }/>
          <NetSalesByDate dateFirst={ dateFirstWatched } dateSecond={ dateSecondWatched } dataList={ netSalesByDayList }/>
          <NetSalesByDaysOfWeek dateFirst={ dateFirstWatched } dateSecond={ dateSecondWatched } dataList={ netSalesByDayList }/>
        </div>
      </Spin>
    </div>
  )
}