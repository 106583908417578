import React, { useEffect } from 'react';
import { Button, Form, Radio, Flex, TimePicker, DatePicker, ConfigProvider } from 'antd';
import { globalState } from '@/stores';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import TimeRangePicker from '@/Components/TimeRangePicker';

const { Item, List } = Form;

const SpecialHours = () => {
  const { i18n } = globalState;
  const PickUpRadioOptions = [
    { value: true, label: i18n.t('pc_online_orders_pickup_hours_open') },
    { value: false, label: i18n.t('pc_online_orders_pickup_hours_closed') },
  ];
  const form = Form.useFormInstance();
  const { componentDisabled } = ConfigProvider.useConfig();

  // 监听变化，触发 re-render
  const handleChange = (objKey: string, value: any, field: any) => {
    const updatedValues = {
      ...form.getFieldValue(field.name),
      [objKey]: value,
    };

    if (objKey === 'dateRange' && value) {
      updatedValues.startTime = value[0];
      updatedValues.endTime = value[1];
    }

    form.setFieldsValue({
      [field.name]: updatedValues,
    });
  };
  const isDisabled = (idx: any) => {
    const fields = form.getFieldValue('specialHours');
    return componentDisabled || !fields[idx].open;
  };

  return (
    <Item>
      <div className="special-hours-item">
        <List name="specialHours">
          {(fields, { add, remove }) =>
            fields.length === 0 ? (
              <Button
                type="link"
                onClick={() =>
                  add({
                    open: true,
                    dateRange: null,
                    intervalTimes: null,
                    startTime: null,
                    endTime: null,
                  })
                }
                disabled={componentDisabled || !form.getFieldValue('specialHourSwitch')}
                icon={<PlusOutlined />}
              >
                {i18n.t('add_special_hours')}
              </Button>
            ) : (
              fields.map((field, index) => {
                return (
                  <React.Fragment key={field.key}>
                    <Flex className="item-box" gap={10}>
                      <Item name={[field.name, 'open']} style={{ padding: '0.5em' }}>
                        <Radio.Group
                          options={PickUpRadioOptions}
                          onChange={(e) => handleChange('open', e.target.value, field)}
                        />
                      </Item>
                      <Item
                        name={[field.name, 'dateRange']}
                        rules={[
                          {
                            required: true,
                            message: i18n.t('please_select_x', { msg: i18n.t('pc_employee_report_time_range') }),
                          },
                        ]}
                        dependencies={[field.name, 'open']}
                      >
                        <DatePicker.RangePicker
                          format="MM/DD/YYYY"
                          allowEmpty={true}
                        />
                      </Item>
                      <Item
                        name={[field.name, 'intervalTimes']}
                        rules={[
                          {
                            required: true,
                            message: i18n.t('please_select_x', { msg: i18n.t('pc_employee_report_time_range') }),
                          },
                        ]}
                      >
                        <TimeRangePicker
                          format="hh:mm A"
                          use12Hours={true}
                          endTimeSet={true}
                        />
                      </Item>
                      {fields.length > 1 && (
                        <Button type="link" onClick={() => remove(index)} icon={<MinusOutlined />} />
                      )}
                      {fields.length < 10 && (
                        <Button
                          type="link"
                          onClick={() =>
                            add({
                              open: true,
                              dateRange: null,
                              intervalTimes: null,
                              startTime: null,
                              endTime: null,
                            })
                          }
                          icon={<PlusOutlined />}
                        />
                      )}
                    </Flex>
                  </React.Fragment>
                );
              })
            )
          }
        </List>
      </div>
    </Item>
  );
};

export default SpecialHours;
