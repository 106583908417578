import { Button, Table, message, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react';
import { ApiExportPayoutReport, ApiQueryExportResult, ApiQueryPayoutSummary } from '@/request/api';
import { getToday, toRes } from '@/utils';
import { TableEmptyContent } from '@/Components/EmptyContent';
import { globalState } from '@/stores';
import dayjs, { Dayjs } from 'dayjs';
import { isAxiosError } from 'axios';
import useGetState from '@/hooks/useGetState';
import PepprDatePicker from '@/Components/DatePicker';
import { useNavigate } from 'react-router-dom';
import Columns from '@/Components/Columns/Columns'
import './index.scss'

interface IParamsState {
  date: Dayjs[];
}

interface ISummaryData {
  txn: number;
  paymentAmount: string;
  refund: string;
  chargeback: string;
  fees: string;
  netPayouts: string;
}

export default function PayoutReport () {
  const { i18n } = globalState;
  const navigate = useNavigate();
  const [today, setToday] = useState(dayjs())
  const [params, setParams] = useState<IParamsState>({
    date: [today.startOf('day'), today.endOf('day')]
  })
  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([])
  const [summaryData, setSummaryData] = useState<ISummaryData>({
    txn: 0, paymentAmount: '$0.00', refund: '$0.00', chargeback: '$0.00', fees: '$0.00', netPayouts: '$0.00'
  } as ISummaryData)
  const [pager, setPager] = useState({
    current: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  })
  const [mouseInfo, setMouseInfo, getMouseInfo] = useGetState({ position: '', isMatch: false })

  const [isExporting, setIsExporting] = useState(false)
  const [intervalId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [activeTitle, setActiveTitle] = useState([])

  let exportTaskId;

  const init = async ()=>{
    const _today = await getToday();
    setToday(_today);
    const sessionDate = sessionStorage.getItem('payoutReportDate') ? sessionStorage.getItem('payoutReportDate').split(',').map(x => dayjs(+x)) : null;
    sessionStorage.removeItem('payoutReportDate')
    const date = sessionDate ?? [_today.startOf('day'), _today.endOf('day')]
    await fetchTableData({ date });
    setParams({ date })
    return () => {
      stopExport();
    }
  }

  useEffect(() => {
    init()
    setActiveTitle(tableColumns.map(item => {
      if (item.hide !== true) return item.dataIndex
    }))
  }, []);

  const fetchTableData = async ({ current, pageSize, date }: { current?: number, pageSize?: number, date?: Dayjs[], first?: boolean } = {}) => {
    const _params = {
      request: {
        beginTime: (date ?? params.date)?.[0].format('YYYY-MM-DD 00:00:00'),
        endTime: (date ?? params.date)?.[1].format('YYYY-MM-DD 23:59:59'),
      },
      current: current ?? pager.current,
      pageSize: pageSize ?? pager.pageSize
    }
    setLoading(true);
    const resp: any = await ApiQueryPayoutSummary(_params);
    const data = resp?.data;
    setLoading(false);
    if (resp?.code !== 0) {
      errorOutput(resp?.message, i18n.t('pc_payout_report_query_failed'))
    }
    stopExport();
    const resultList = data?.list ?? []
    setTableData(resultList)
    data?.summary && setSummaryData({ ...data.summary })
    setPager((prev) => ({
      ...prev,
      pageSize: pageSize ?? pager.pageSize,
      total: data?.total ?? 0,
      current: current ?? data?.current
    }))
  }

  const handleTableChange = (_pagination) => {
    fetchTableData({ current: _pagination.current, pageSize: _pagination.pageSize });
  }

  const errorOutput = (err, defaultOutput) => {
    if (isAxiosError(err) && [504, 408].includes(err.request.status)) {
      message.error(i18n.t('pc_timeout_exceeded'))
    } else {
      defaultOutput && message.error(defaultOutput)
    }
  }

  const handleTableRowClick = (record) => {
    return {
      onClick: () => {
        const _mouseInfo = getMouseInfo();
        if (_mouseInfo.isMatch) {
          sessionStorage.setItem('payoutReportDate', params.date.map(x => x.valueOf()).join(','))
          navigate(`/payments/payoutDetail?id=${ record.payoutBatch }`)
        }
      },
      onMouseDown: (event) => {
        setMouseInfo({ position: `${ event.clientX }+${ event.clientY }`, isMatch: false })
      },
      onMouseUp: (event) => {
        const _mouseInfo = getMouseInfo();
        if (_mouseInfo.position === `${ event.clientX }+${ event.clientY }`) {
          setMouseInfo({ ...mouseInfo, isMatch: true })
        }
      },
    }
  }

  // region 导出相关
  const handleExport = async () => {
    setIsExporting(true)
    const [err, data] = await toRes(ApiExportPayoutReport({
      params: {
        req: {
          beginTime: params.date[0].format('YYYY-MM-DD 00:00:00'),
          endTime: params.date[1].format('YYYY-MM-DD 23:59:59'),
        }
      }
    }), { showErrMsg: false });
    exportTaskId = data?.exportTaskId ?? null;
    if (err || !exportTaskId) {
      errorOutput(err, i18n.t('pc_payout_report_download_failed'))
      setIsExporting(false)
      return;
    }
    getExportResult();
  }

  const getExportResult = async () => {
    const [err, data] = await toRes(ApiQueryExportResult({ params: { req: { exportTaskId } } }), { showErrMsg: false });
    if (err) {
      errorOutput(err, i18n.t('pc_payout_report_download_failed'))
      setIsExporting(false)
      stopExport();
      return;
    }
    if (data?.result && data?.url) {
      // 开启下载
      handleDownload(data.url);
      message.success(i18n.t('pc_payout_report_successfully_downloaded'))
      setIsExporting(false);
      exportTaskId = null;
    } else {
      const id = setTimeout(async () => {
        getExportResult();
      }, 2000);
      setTimeoutId(id);
    }
  }

  const stopExport = () => {
    if (intervalId !== null) {
      clearTimeout(intervalId);
      setTimeoutId(null);
    }
  }

  const onChangeColumns = (list) => {
    setActiveTitle(list)
  }

  const handleDownload = (url) => {
    if (!url) return;
    const urlObject = new URL(url);
    const filename = decodeURIComponent(urlObject.pathname.substring(urlObject.pathname.lastIndexOf('/') + 1));
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // endregion

  // region 时间选择器相关
  const handleChangeDate = (values) => {
    setParams({ date: values })
    fetchTableData({ date: values, current: 1 });
  }

  function getDefaultDate ({ todayDate }: { todayDate?: Dayjs } = {}) {
    const _today = todayDate ?? today
    switch (_today.day()) {
    case 0:
      return _today.subtract(3, 'day');
    case 1:
      return _today.subtract(4, 'day');
    case 2:
      return _today.subtract(4, 'day');
    default:
      return _today.subtract(2, 'day');
    }
  }

  const summaryList = [
    { title: i18n.t('dashboard_payout_report_txn'), value: summaryData?.txn },
    { title: i18n.t('pc_payout_report_payment_amount'), value: summaryData?.paymentAmount },
    { title: i18n.t('pc_payout_report_refund'), value: summaryData?.refund },
    { title: i18n.t('pc_payout_report_chargeback'), value: summaryData?.chargeback },
    { title: i18n.t('pc_payout_report_fees'), value: summaryData?.fees },
    { title: i18n.t('pc_payout_report_net_payouts'), value: summaryData?.netPayouts },
  ]

  const tableColumns = [
    { dataIndex: 'payoutDate', key: 'payoutDate', title: i18n.t('pc_payout_report_payout_date'), width: 120, hide: false },
    { dataIndex: 'salesPeriod', key: 'salesPeriod', title: i18n.t('pc_payout_report_sales_period'), width: 300 },
    { dataIndex: 'txn', key: 'txn', title: '#Txn', width: 80 },
    { dataIndex: 'paymentAmount', key: 'paymentAmount', title: i18n.t('pc_payout_report_payment_amount'), width: 150 },
    { dataIndex: 'refund', key: 'refund', title: i18n.t('pc_payout_report_refund'), width: 100 },
    { dataIndex: 'chargeback', key: 'chargeback', title: i18n.t('pc_payout_report_chargeback'), width: 100 },
    { dataIndex: 'fees', key: 'fees', title: i18n.t('pc_payout_report_fees'), width: 80 },
    { dataIndex: 'netPayouts', key: 'netPayouts', title: i18n.t('pc_payout_report_net_payouts'), width: 100 },
  ]

  return (
    <div className='payoutReport'>
      <div className='titleWrap'>
        <div className='title'>{ i18n.t('pc_payout_report_title') }</div>
        <div className='actionsWrap'>
          <Tooltip
            placement="topRight"
            overlayInnerStyle={ { width: 270 } }
            overlayStyle={ { inset: 'auto 60px 923px auto' } }
            title={ i18n.t('pc_payout_report_export_tips') }
          >
            <Button loading={ isExporting } onClick={ handleExport }>{ i18n.t('pc_payout_report_export') }</Button>
          </Tooltip>
        </div>
      </div>
      <div className='filterWrap'>
        <PepprDatePicker value={ params.date as [Dayjs, Dayjs] } onChange={ handleChangeDate } today={ today }/>
        <Columns value={activeTitle} options={tableColumns.map(item => ({ label: item.title, value: item.dataIndex }))} onChange={onChangeColumns} />
      </div>
      <div className='summaryWrap'>
        { summaryList.map((item, index) => (
          <div className='summaryItem' key={ index }>
            <div className='name'>{ item.title }</div>
            <div className='price'>{ item.value }</div>
          </div>)
        ) }
      </div>
      <div className='tableWrap'>
        <Table
          // columns={ tableColumns }
          columns={ tableColumns.filter(x => {
            return activeTitle.includes(x.dataIndex)
          }) }
          dataSource={ tableData.map((x, i) => ({ ...x, key: i })) }
          rowKey={ 'key' }
          pagination={ pager }
          onChange={ handleTableChange }
          scroll={ { x: 'max-content' } }
          locale={ { emptyText: TableEmptyContent } }
          onRow={ handleTableRowClick }
          rowClassName='tableRow'
          loading={ loading }
        />
      </div>
    </div>
  )
}