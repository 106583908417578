import React, { useState } from 'react';
import { Menu, Dropdown, Button, message, Image, MenuProps } from 'antd';
import { IDropMenuExportParams, IExportProps } from './interface';
import { globalState } from '@/stores';
import { downloadFile, errorOutput } from '@/utils';
import { ApiApaasQueryExportResult } from '@/request/api';
import VectorPng from '@/assets/Vector.png'
import { defaultButtonName, defaultDisabled, defaultItems, defaultType, defaultWidth } from './defaultVal';

const PepprExportComponent = (props: IExportProps) => {
  // 从 globalState 中获取 i18n 对象，用于国际化
  const { i18n } = globalState;

  // 从 props 中解构出 type 和 fetchApiExport
  const {
    type,
    disabled,
    width,
    isDropDown,
    menuList,
    fetchApiExport
  } = props;


  // 定义 isExporting 状态，表示是否正在导出，初始值为 false
  const [isExporting, setIsExporting] = useState(false)

  // 定义 intervalId 状态，用于存储定时器 ID，初始值为 null
  const [intervalId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  // 定义导出任务 ID
  let exportTaskId;


  // 导出相关
  const handleExport = async (exportType?: IDropMenuExportParams) => {
    setIsExporting(true)
    try {
      const { exportStatus, exportId, exportFailErrorMsg } = await fetchApiExport(exportType);
      exportTaskId = exportId ?? null;
      if (!exportStatus) {
        errorOutput(exportFailErrorMsg, i18n.t('pc_payout_report_download_failed'), i18n)
        setIsExporting(false)
        return;
      }
      getExportResult();
    } catch (err) {
      errorOutput(err, i18n.t('pc_payout_report_download_failed'), i18n)
      setIsExporting(false)
    }
  }

  const getExportResult = async () => {
    try {
      const res: any = await ApiApaasQueryExportResult({ params: { req: { exportTaskId } } });
      if (res.code !== 0) {
        errorOutput(res, i18n.t('pc_payout_report_download_failed'), i18n)
        setIsExporting(false)
        stopExport();
        return;
      }
      const data = res.data;
      if (data?.result && data?.url) {
        // 开启下载
        downloadFile(data.url);
        message.success(i18n.t('pc_payout_report_successfully_downloaded'))
        setIsExporting(false);
        exportTaskId = null;
      } else {
        const id = setTimeout(async () => {
          getExportResult();
        }, 2000);
        setTimeoutId(id);


        // 超过1分钟则关闭定时器
        setTimeout(() => {
          if (intervalId !== null) {
            clearTimeout(intervalId);
            setTimeoutId(null);
            setIsExporting(false);
          }
        }, 60000);
      }
    } catch (err) {
      errorOutput(err, i18n.t('pc_payout_report_download_failed'), i18n)
      setIsExporting(false)
      stopExport();
    }
  }

  const stopExport = () => {
    if (intervalId !== null) {
      clearTimeout(intervalId);
      setTimeoutId(null);
    }
  }


  // icon 导出
  const handleMenuClick = (e) => {
    handleExport(e.key);
  };


  /**
   * 渲染导出按钮
   * @returns {JSX.Element} 返回一个按钮组件
   */
  const renderExportButton = (): JSX.Element => (
    <Button
      type="primary"
      loading={isExporting}
      onClick={() => !isDropDown && handleExport()}
      disabled={disabled || defaultDisabled}
    >
      {i18n.t('export_pc') || defaultButtonName}
    </Button>
  );

  /**
   * 渲染导出图标
   * @returns {JSX.Element} 返回一个 Image 组件，作为导出图标
   * - style: 设置图标的样式，光标为指针，表示可点击
   * - width: 设置图标的宽度，默认为 14.5
   * - src: 图标的图片来源，使用 VectorPng
   * - preview: 禁用图片预览功能
   */
  const renderExportIcon = () => (
    <Image
      style={{ cursor: 'pointer' }}
      width={width || defaultWidth}
      src={VectorPng}
      preview={false}
    />
  );

  /**
   * 渲染下拉菜单的内容
   * 根据 type 判断渲染导出按钮或导出图标
   * @returns {JSX.Element} 返回一个按钮组件或图标组件
   */
  const renderDropdownContent = () => (
    (type || defaultType)  === 'button' ? renderExportButton() : renderExportIcon()
  );

  /**
   * 渲染下拉菜单组件
   * @returns {JSX.Element} 返回一个 Dropdown 组件
   * - menu: 配置下拉菜单的内容和点击事件
   *   - items: 下拉菜单的选项列表
   *   - onClick: 处理菜单点击事件的函数
   * - placement: 设置下拉菜单的显示位置，位于触发元素的左下方
   * - overlayStyle: 设置下拉菜单的样式，这里设置宽度为 100
   * - children: 下拉菜单的触发元素，这里根据 type 渲染按钮或图标
   */
  const renderDropdown = () => (
    <Dropdown
      menu={{
        items: menuList || defaultItems,
        onClick: handleMenuClick
      }}
      placement="bottomRight"
      overlayStyle={{ width: 100 }}
    >
      {renderDropdownContent()}
    </Dropdown>
  );

  /**
   * 渲染非下拉菜单的内容
   * 根据 type 判断渲染导出按钮或导出图标
   * @returns {JSX.Element} 返回一个包含按钮或图标的 div 容器
   */
  const renderNonDropdown = () => (
    <div>
      {(type || defaultType)  === 'button' ? renderExportButton() : renderExportIcon()}
    </div>
  );


  return (
    <div>
      {isDropDown && !disabled ? renderDropdown() : renderNonDropdown()}
    </div>
  );
};

export default PepprExportComponent;