import { Button, DrawerProps, Form, Input, Space, Drawer, Radio, Spin, Select, message } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { globalState } from '@/stores';
import { isEmpty, toRes } from '@/utils'
import { getPrinterInfo, listPrinterModel, queryDevices, listPrepStations, savePrinter, addPrinter, getQueryCashDrawerUnbindList } from '@/request/PrintersAndCashDrawers';
import './index.scss'
import { PaperWidthList, LinkWithFOH, CashDrawerTypeEnum } from '../enum';
interface Props {
  open: boolean,
  setOpen: Dispatch<SetStateAction<any>>,
  reloadTableData: () => void,
  type: 0 | 1, // 0代表编辑 1代表新增
  printId: string
}

const { Option } = Select

export default function PrintDrawer (props: Props) {
  const [printInfoForm] = Form.useForm();
  const [placement, setPlacement] = useState<DrawerProps['placement']>('right');
  const [printInfo, setPrintInfo] = useState<any>({});
  const [printerSelect, setPrinterSelect] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [prepStations, setPrepStations] = useState<any>({});
  const [kitchenList, setKitchenList] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [cashDrawerList, setCashDrawerList] = useState([]);

  const [foh, setFoh] = useState<boolean>(false);
  const i18n = globalState.i18n;


  // 初始化数据
  const initData = async () => {
    setLoading(true);
    const [[, printList], [, deviceList], [, prepStations]] = await Promise.all([
      toRes(listPrinterModel(), { showErrMsg: false }),
      toRes(queryDevices({ typeList: [1, 4] }), { showErrMsg: false }),
      toRes(listPrepStations({ status: false }), { showErrMsg: false })
    ])
    setPrinterSelect(printList);
    setDeviceList(deviceList);
    console.log('prepStations', prepStations);

    setPrepStations(prepStations);
    setKitchenList(prepStations?.kitchens ? prepStations?.kitchens.map(x => ({
      ...x,
      name: x.name,
      value: x.id,
      displayName: x.name
    })) : []);
    setFoh(true);
    printInfoForm.setFieldValue('linkWithFOH', true);
    // printInfoForm.setFieldValue('cashDrawer', CashDrawerTypeEnum.NO_CASH_DRAWER);
    if (props.printId) {
      const [, data] = await toRes(getPrinterInfo({ PrinterId: props.printId, cashDrawerId: '1' }), { showErrMsg: false });
      setPrintInfo(data);
      if (data) {
        const { paperWidth: { value }, linkWithFOH, posDevices, prepStations, cashDrawerId } = data;
        printInfoForm.setFieldsValue(data);
        printInfoForm.setFieldsValue({
          paperWidth: value,
          linkWithFOH: !!linkWithFOH,
          posDevices: posDevices?.map(ele => ele.id),
          kitchenList: prepStations?.map(ele => ele.id),
          cashDrawerId
        })
        setFoh(!!data.linkWithFOH);
      }
    }
    setLoading(false);
  }


  const getPrintCashDrawer = async () => {
    const res = await getQueryCashDrawerUnbindList({
      printId: props.printId
    });

    if (res?.code === 0) {
      setCashDrawerList(res?.data || [])
    }

  }

  useEffect(() => {
    if (props.open) {
      initData();
      getPrintCashDrawer()
    }
  }, [props.open, props.printId, props.type])

  const onClose = () => {
    printInfoForm.resetFields();
    props.setOpen(prevState => !prevState);
  }

  const onSave = async () => {
    const params = await printInfoForm.validateFields();
    const paperWidth = [PaperWidthList.find(f => f.value === params.paperWidth)].map(m => ({ displayName: m.label, value: m.value }))[0]
    const posDevices = (params.linkWithFOH && params?.posDevices?.length) ? deviceList.filter(f => params.posDevices.includes(f.id)) : [];

    const prepStationList = params.kitchenList ? kitchenList.filter(f => params.kitchenList.includes(f.id)) : []
    const req = {
      cashDrawerId: params.cashDrawerId || null,
      ipAddress: params.ipAddress,
      linkWithFOH: params.linkWithFOH ? prepStations?.frontOfHouse : null,
      modelId: params.modelId,
      name: params.name,
      paperWidth,
      posDevices,
      prepStations: prepStationList
    }
    let state = false;
    if (props.printId) {
      req['id'] = printInfo.id;
      const data = await savePrinter(req);
      state = data.code === 0;
    } else {
      const data = await addPrinter(req);
      state = data.code === 0;
    }
    if (state) {
      printInfoForm.resetFields();
      props.reloadTableData();
      message.success(i18n?.t('message_success'))
    }
  }

  const closeDrawer = () => {
    props.setOpen(prevState => !prevState);
  }

  const layout = {
    labelCol: { span: 8 }
  };

  return <Drawer
    title={i18n.t('printer_and')}
    placement={placement}
    width={650}
    onClose={closeDrawer}
    open={props.open}
    closeIcon={false}
    style={{ 'backgroundColor': '#F5F5F5', 'paddingBottom': '80px' }}
    size="large"
    extra={
      <Space>
        <Button onClick={onClose}>{i18n.t('cancel')}</Button>
        <Button type="primary" onClick={onSave}>
          {i18n.t('save')}
        </Button>
      </Space>
    }
  >
    <div className="pos-monitor-content-card">
      <Spin spinning={loading}>
        <Form
          {...layout}
          form={printInfoForm}
          validateTrigger="onSubmit"
          labelAlign="left"
        >
          <Form.Item
            label={
              <div>
                <span className="printers-and-cash-drawers-required-symobol">*</span>{i18n?.t('printer_name')}
              </div>
            }
            name="name"
            rules={[
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (isEmpty(value)) {
                    return Promise.reject(i18n?.t('please_enter_x', { msg: i18n?.t('printer_name') }));
                  }
                  return Promise.resolve()
                }
              }),
            ]}>
            <Input required onBlur={() => { printInfoForm.validateFields(['name']) }} />
          </Form.Item>

          <Form.Item
            label={
              <div>
                <span className="printers-and-cash-drawers-required-symobol">*</span>{i18n.t('ip_address')}
              </div>
            }
            name="ipAddress"
            rules={[
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (isEmpty(value)) {
                    return Promise.reject(i18n?.t('please_enter_x', { msg: i18n?.t('ip_address') }));
                  }
                  return Promise.resolve()
                }
              }),
            ]}>
            <Input onBlur={() => { printInfoForm.validateFields(['ipAddress']) }} />
          </Form.Item>

          <Form.Item
            label={
              <div>
                <span className="printers-and-cash-drawers-required-symobol">*</span>{i18n.t('print_gearOpening_bill_pc_model')}
              </div>
            }
            name="modelId"
            rules={[
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (isEmpty(value)) {
                    return Promise.reject(i18n?.t('please_select_x', { msg: i18n?.t('print_gearOpening_bill_pc_model') }));
                  }
                  return Promise.resolve()
                }
              }),
            ]}>
            <Select
              style={{ width: '100%' }}
              optionLabelProp="label"
              allowClear
              onBlur={() => { printInfoForm.validateFields(['modelId']) }}
            >
              {
                (printerSelect || []).map(ele => (
                  <Option value={ele.id} label={ele.model} key={ele.id}>
                    <div>
                      {ele.model}
                    </div>
                  </Option>
                ))
              }
            </Select>
          </Form.Item>

          <Form.Item
            label={
              <div>
                <span className="printers-and-cash-drawers-required-symobol">*</span>{i18n.t('print_gearOpening_bill_pc_paper_width')}
              </div>
            }
            name="paperWidth"
            rules={[
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (isEmpty(value)) {
                    return Promise.reject(i18n?.t('please_select_x', { msg: i18n?.t('print_gearOpening_bill_pc_paper_width') }));
                  }
                  return Promise.resolve()
                }
              }),
            ]}>
            <Radio.Group onBlur={() => { printInfoForm.validateFields(['paperWidth']) }}>
              {
                (PaperWidthList || []).map(ele => (
                  <Radio value={ele.value} key={ele.label}> {ele.label} </Radio>
                ))
              }
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={
              <div>
                <span className="printers-and-cash-drawers-required-symobol">*</span>{i18n.t('link_to_front_of_house')}
              </div>
            }
            name="linkWithFOH"
            rules={[
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (isEmpty(value)) {
                    return Promise.reject(i18n?.t('please_select_x', { msg: i18n?.t('link_to_front_of_house') }));
                  }
                  return Promise.resolve()
                }
              }),
            ]}>
            <Radio.Group onChange={(val) => {
              setFoh(val.target.value);
              printInfoForm.setFieldValue('posDevices', []);
            }} onBlur={() => {
              printInfoForm.validateFields(['linkWithFOH'])
            }}>
              {
                (LinkWithFOH || []).map((ele, index) => (
                  <Radio value={ele.value} key={index}> {i18n?.t(ele.label)} </Radio>
                ))
              }
            </Radio.Group>
          </Form.Item>
          {
            foh && <Form.Item
              label={
                <div>
                  {i18n.t('pc_device_link_to_POS_station')}
                </div>
              }
              name="posDevices">
              <Select
                style={{ width: '100%' }}
                optionLabelProp="label"
                mode="multiple"
                maxTagCount="responsive"
                onBlur={() => {
                  printInfoForm.validateFields(['posDevices'])
                }}
              >
                {
                  (deviceList || []).map(ele => (
                    <Option value={ele.id} label={ele.sn} key={ele.id}>
                      <div>
                        {ele.sn}
                      </div>
                    </Option>
                  ))
                }
              </Select>
            </Form.Item>
          }

          <Form.Item
            label={
              <div>
                {i18n.t('kitchen')}
              </div>
            }
            name="kitchenList">
            <Select
              style={{ width: '100%' }}
              optionLabelProp="label"
              mode="multiple"
              maxTagCount='responsive'
            >
              {
                (kitchenList || []).map((ele, index) => (
                  <Option value={ele.value} label={ele.name} key={index}>
                    <div>
                      {ele.name}
                    </div>
                  </Option>
                ))
              }
            </Select>
          </Form.Item>

          <Form.Item
            label={
              <div>
                {i18n.t('print_gearOpening_bill_pc_cashDrawer')}
              </div>
            }
            name="cashDrawerId">
            <Select
              style={{ width: '100%' }}
              disabled={cashDrawerList.length === 0}
              options={cashDrawerList.map(item => ({ label: item.name, value: String(item.id) }))}
              allowClear
            />
            {
              cashDrawerList.length === 0 && <div style={{ color: 'red' }}>{i18n.t('dashboard_cash_drawer_not_available')}</div>
            }
          </Form.Item>
        </Form>
      </Spin>
    </div>
  </Drawer>
}