import React, { useEffect, useState } from 'react';
import { debounce } from '@/utils/throttleDebounce'
import { globalState } from '@/stores';
import { Button, Dropdown, Form, Input, Menu, MenuProps, message, Select, Table, Tabs, Tag, TreeSelect } from 'antd';
import { MoreOutlined, SyncOutlined } from '@ant-design/icons'
import { SelectEmptyContent, TableEmptyContent } from '@/Components/EmptyContent';
import dayjs from 'dayjs';
import useGetState from '@/hooks/useGetState';
import { ApiQueryAllDiningOptions, ApiQueryOrderReportViewList, ApiIsOnlineOrderingMerchant, ApiExportOrderReport, ApiApaasQueryExportResult } from '@/request/api';
import { formatBackI18n, formatDollar, formatPrice, formatTimestampWithoutTimeZone, getTimeZone, getToday as getTodayFn, removeNullUndefinedFields, toRes, formatParams, errorOutput, downloadFile } from '@/utils';
import { SorterResult } from 'antd/es/table/interface';
import GlobalDrawer from '@/utils/GlobalDrawer';
import OrderDetail from './OrderDetail'
import { ColumnsType } from 'antd/es/table';
import PepprDatePicker from '@/Components/DatePicker';
import { useNavigate } from 'react-router-dom';
import './index.scss'
import { EReport_Status } from '@/types';
import PepprExportComponent from '@/Components/Export/PepprExportComponent';
import { IDropMenuExportParams, IExportResult } from '@/Components/Export/interface';

interface IPage {
  current: number;
  pageSize: number;
  total: number;
  showSizeChanger: boolean;
  showQuickJumper: boolean;
  showTotal: (total: number) => string;
}

export default function OrdersReport () {
  const { i18n } = globalState;
  const navigate = useNavigate();
  const [form] = Form.useForm()

  const [isInit, setIsInit] = useState(false)
  const [isOnlineOrderingMerchant, setIsOnlineOrderingMerchant] = useState(false)
  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([])
  const [sortedInfo, setSortedInfo, getSortedInfo] = useGetState<SorterResult<any>>({});
  const [pager, setPager, getPager] = useGetState<IPage>({
    current: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  })
  const [today, setToday, getToday] = useGetState(dayjs())
  const [diningOptionsList, setDiningOptionsList] = useState([])
  const [params, setParams, getParams] = useGetState({
    ascDecs: 0,
    orderStatus: '-1'
  })


  const getFormInitialValues = () => ({
    date: [getToday().startOf('day'), getToday().endOf('day')],
    diningOptionIds: [],
    salesChannels: [],
    advancedSearchIdDisplay: ''
  })

  const ORDER_STATUS_TYPE = {
    ALL: { key: '-1', label: i18n.t('orderList_pc_all') },
    SAVED: { key: '100', label: i18n.t('orderList_pc_saved') },
    CONFIRMED: { key: '200', label: i18n.t('orderList_pc_confirmed') },
    FINISHED: { key: '300', label: i18n.t('orderList_pc_finished') },
    CANCELED: { key: '400', label: i18n.t('rms_overseas_transaction_order_OrderItemStatusEnum_CANCELLED') },
    CLOSED: { key: '500', label: i18n.t('orderList_pc_closed') },
  }


  const SERVICE_TYPES = [
    { value: 1, label: i18n.t('createMenuItem_pc_pos') },
    { value: 2, label: i18n.t('Dashboard_Orders_Online_Ordering') }
  ]
  const tableColumns = [
    { dataIndex: 'posDisplayNo',
      key: 'posDisplayNo',
      title: i18n.t('orders_table_check#'),
      width: 100 },
    { dataIndex: 'diningOptionName',
      key: 'diningOptionName',
      title: i18n.t('orders_table_diningOption'),
      width: 170 },
    {
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      title: i18n.t('orders_table_orderStatus'),
      width: 200,
      render: (val, row) => (
        <div className='colOrderStatus'>
          { formatBackI18n(val?.displayName, i18n) }{ (row?.orderStatus?.value == 300 && row?.hasRefund?.value == 1) && <Tag>{i18n.t('dashboard_report_part_refund')}</Tag> }
        </div>)
    },
    {
      dataIndex: 'standardCreateTime',
      key: 'standardCreateTime',
      title: i18n.t('orders_table_orderCreated'),
      width: 230,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'standardCreateTime' ? sortedInfo.order : null,
      render: (val) => {
        return formatTimestampWithoutTimeZone(val) || '-'
      }
    },
    {
      dataIndex: 'standardCloseTime',
      key: 'standardCloseTime',
      title: i18n.t('orders_table_orderClosed'),
      width: 230,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'standardCloseTime' ? sortedInfo.order : null,
      visible: () => ['300', '500', '-1'].includes(params.orderStatus),
      render: (val) => formatTimestampWithoutTimeZone(val) || '-'
    },
    {
      dataIndex: 'standardCancelTime',
      key: 'standardCancelTime',
      title: i18n.t('orders_table_orderCanceled'),
      width: 230,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'standardCancelTime' ? sortedInfo.order : null,
      visible: () => ['400', '-1'].includes(params.orderStatus),
      render: (val) => formatTimestampWithoutTimeZone(val) || '-'
    },
    {
      dataIndex: 'server',
      key: 'server',
      title: i18n.t('orders_table_server'),
      width: 100,
      render: (_, row) => row.server?.fullName || '-'
    },
    { dataIndex: 'tax',
      key: 'tax',
      title: i18n.t('orders_table_taxes'),
      width: 100,
      render: (val) => formatDollar(val) + formatPrice(val) },
    { dataIndex: 'tips',
      key: 'tips',
      title: i18n.t('orders_table_tips'),
      width: 100,
      render: (val) => formatDollar(val) + formatPrice(val) },
    { dataIndex: 'receivable',
      key: 'receivable',
      title: i18n.t('orders_table_total'),
      width: 100,
      render: (val) => formatDollar(val) + formatPrice(val) },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: i18n.t('orders_table_actions'),
      width: 100,
      fixed: 'right',
      render: (_, row) => <Button type="link" className='btnDetail' onClick={ () => handleOpenDetail(row) }><MoreOutlined/>{ i18n.t('details') }</Button>
    },
  ]

  const init = async () => {
    setToday(await getTodayFn({ timeZone: getTimeZone() }))
    form.setFieldValue('date', [getToday().startOf('day'), getToday().endOf('day')])
    fetchDiningOptions();
    fetchTableData();
    const res = await ApiIsOnlineOrderingMerchant()
    if (res.code === 0) {
      setIsOnlineOrderingMerchant(res.data)
    }
  }
  useEffect(() => {
    init()
  }, []);

  const fetchDiningOptions = async () => {
    const [_, data = []] = await toRes(ApiQueryAllDiningOptions());
    setDiningOptionsList(data.map(x => ({ value: x.id, title: x.name, key: x.id })))
  }

  const fetchTableData = async () => {
    const formParams = isInit ? form.getFieldsValue() : getFormInitialValues();
    const otherParams = getParams();
    const sortParams = getSortedInfo();
    const pagerParams = getPager();
    let field = 'created_time';
    if (sortParams.order) {
      switch (sortParams.columnKey) {
      case 'standardCreateTime':
        field = 'created_time'
        break;
      case 'standardCloseTime':
        field = 'check_out_time'
        break;
      case 'standardCancelTime':
        field = 'cancel_time'
        break;
      default:
        break;
      }
    }
    const result = {
      request: {
        advancedSearchIdDisplay: formParams.advancedSearchIdDisplay == '' ? null : formParams.advancedSearchIdDisplay,
        beginTime: formParams.date[0].format('YYYY-MM-DD 00:00:00'),
        endTime: formParams.date[1].format('YYYY-MM-DD 23:59:59'),
        orderStatus: otherParams.orderStatus ? [otherParams.orderStatus] : ['-1'],
        timeSortType: getTimeSortType(),
        ...formatParams({ diningOptionIds: formParams.diningOptionIds, salesChannels: formParams.salesChannels }),
      },
      orderBy: {
        field: field,
        order: sortParams.order && sortParams.order === 'ascend' ? 'ASC' : 'DESC' // 原ascDesc字段，"ASC"(原1) OR "DESC"(原0)，默认倒序DESC
      },
      current: pagerParams.current,
      pageSize: pagerParams.pageSize
    }
    setLoading(true)
    const [_, data] = await toRes(ApiQueryOrderReportViewList(removeNullUndefinedFields(result)));
    setLoading(false)
    setIsInit(true);
    setTableData(data?.list ?? [])
    setPager({
      ...pager,
      pageSize: pagerParams.pageSize,
      total: data?.total ?? 0,
      current: data?.current ?? 1
    })
  }
  const debounceFetchTableData = debounce(500, fetchTableData);


  const handleFormValuesChange = (changedValues, allValues) => {
    setPager({ ...pager, current: 1 });
    if ('advancedSearchIdDisplay' in changedValues) {
      debounceFetchTableData();
    } else {
      fetchTableData();
    }
  }

  const handleStatusChange = (val) => {
    setParams({ ...params, orderStatus: val.toString() })
    setPager({ ...pager, current: 1 });
    fetchTableData();
  }

  const handleTableChange = (_pagination, filters, sorter) => {
    console.log(_pagination, filters, sorter)
    setSortedInfo(sorter);
    setPager({ ...pager, ..._pagination });
    fetchTableData();
  }

  const getTimeSortType = () => {
    const formParams = form.getFieldsValue();
    const sortParams = getSortedInfo();
    const sortTypeObj = {
      createTime: '100',
      cancelTime: '400',
      closeTime: '500'
    };
    let sortTypeCode;
    if (sortParams.columnKey == 'createTime') {
      sortTypeCode = sortTypeObj.createTime;
    } else {
      if (formParams.orderStatus === '-1' || formParams.orderStatus == '300' || !formParams.orderStatus) {
        sortTypeCode = '300';
      } else {
        sortTypeCode = sortTypeObj[sortParams.columnKey as keyof typeof sortTypeObj];
      }
    }
    return sortTypeCode
  }

  const handleOpenDetail = (row) => {
    GlobalDrawer.open({ component: <OrderDetail/>, navigate: navigate, props: { orderItem: row } })
  }
  const tabBarExtraContent = {
    left: <div className="main-tab-title">{ i18n.t('pc_reports_orders_orders') }</div>,
  }


  // 导出相关
  const fetchApiExport = async (exportType: IDropMenuExportParams) => {
    const params = {
      ...getRequestParams(),
      exportType: EReport_Status[exportType],
    }
    const res: any = await ApiExportOrderReport(removeNullUndefinedFields(params));

    const exportResult: IExportResult = {
      exportStatus: res.code === 0,
      exportId: res?.data?.exportTaskId ?? null,
      exportFailErrorMsg: res,
    }

    return exportResult
  }


  const getRequestParams = () => {
    const formParams = form.getFieldsValue();
    return {
      beginTime: formParams.date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: formParams.date[1].format('YYYY-MM-DD 23:59:59'),
    }
  }


  return (
    <div className='ordersReport'>
      <Tabs
        className="tabsWrap"
        defaultActiveKey={ ORDER_STATUS_TYPE.ALL.key }
        tabBarExtraContent={ tabBarExtraContent }
        onChange={ handleStatusChange }
        items={ [ORDER_STATUS_TYPE.ALL, ORDER_STATUS_TYPE.FINISHED, ORDER_STATUS_TYPE.CANCELED, ORDER_STATUS_TYPE.CLOSED] }
      />
      <div className="filterWrap">
        <Form form={ form } layout="inline" initialValues={ getFormInitialValues() } onValuesChange={ handleFormValuesChange }>
          <Form.Item name={ 'advancedSearchIdDisplay' } normalize={ (val) => val.trim() }>
            <Input allowClear placeholder={ i18n.t('orders_search_placeholder') } style={ { width: '250px' } }/>
          </Form.Item>
          <Form.Item name={ 'date' }>
            <PepprDatePicker today={ today }/>
          </Form.Item>
          <Form.Item name={ 'diningOptionIds' }>
            <TreeSelect
              popupClassName="diningOptionSelectPopup"
              style={ { minWidth: '250px' } }
              showSearch
              treeCheckable
              maxTagCount={ 1 }
              notFoundContent={ SelectEmptyContent }
              placeholder={ i18n.t('select_x', { msg: i18n.t('pc_reports_orders_dining_options') }) }
              treeData={ diningOptionsList }
              filterTreeNode={ (inputValue, treeNode) => treeNode.props.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 }
            />
          </Form.Item>
          {isOnlineOrderingMerchant && <Form.Item name={ 'salesChannels' }>
            <TreeSelect
              popupClassName="diningOptionSelectPopup"
              style={ { minWidth: '250px' } }
              showSearch
              treeCheckable
              maxTagCount={ 1 }
              notFoundContent={ SelectEmptyContent }
              placeholder={ i18n.t('Dashboard_Orders_Select_Sales_Channels') }
              treeData={ SERVICE_TYPES }
              filterTreeNode={ (inputValue, treeNode) => treeNode.props.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 }
            />
          </Form.Item>}
        </Form>
        <div className="right-wrap">
          <Button type="link" onClick={ fetchTableData }><SyncOutlined/></Button>
        </div>
      </div>

      <div className='tableWrap'>
        <Table
          columns={ tableColumns.filter(x => x?.visible ? x.visible() : true) as ColumnsType }
          onChange={ handleTableChange }
          loading={ loading }
          dataSource={ tableData.map((x, i) => ({ ...x, key: i })) }
          pagination={ pager }
          scroll={ { x: 'max-content' } }
          locale={ { emptyText: TableEmptyContent } }
        />
      </div>

      <div className="action-wrap">
        <PepprExportComponent
          type='button'
          isDropDown={true}
          disabled={tableData.length === 0}
          fetchApiExport={(exportType) => fetchApiExport(exportType)}
        />
      </div>
    </div>
  )
}