import React, { useEffect, useState } from 'react';
import { globalState } from '@/stores';
import { Button, message, Radio, RadioChangeEvent, Space, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { ApiQueryOrderBySeatConfig, ApiQueryTaskCheckListConfig, ApiSaveOrderBySeatConfig, ApiSaveTaskCheckListConfig } from '@/request/api';

enum ShiftScreenStatus {
  ON = 1,
  OFF = 2,
}


enum TakeNonCashTipsValue {
  PaidOutFromTheCashDrawer = 1,
  ProcessedManually = 2,
}

enum DeclareTipsValue {
  DeclareCashTips = 1,
  EmployeesDeclareTotalTips = 2,
}

export default function ShiftScreen () {
  const { i18n } = globalState;
  const [loading, setLoading] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [initData, setInitData] = useState<{
    shiftScreenValue: ShiftScreenStatus,
    takeNonCashTipsValue: TakeNonCashTipsValue
    declareTipsValue: DeclareTipsValue
  }>({
    shiftScreenValue: ShiftScreenStatus.OFF,
    takeNonCashTipsValue: TakeNonCashTipsValue.ProcessedManually,
    declareTipsValue: DeclareTipsValue.DeclareCashTips
  });
  const [shiftScreenValue, setShiftScreenValue] = useState<ShiftScreenStatus>(ShiftScreenStatus.OFF);
  const [takeNonCashTipsValue, setTakeNonCashTipsValue] = useState<TakeNonCashTipsValue>(TakeNonCashTipsValue.ProcessedManually);
  const [declareTipsValue, setDeclareTipsValue] = useState<DeclareTipsValue>(DeclareTipsValue.DeclareCashTips);

  const cancelEdit = () => {
    setIsEdit(false);
    setShiftScreenValue(initData.shiftScreenValue)
    setTakeNonCashTipsValue(initData.takeNonCashTipsValue)
    setDeclareTipsValue(initData.declareTipsValue)
  };

  const saveOrderScreenConfig = async () => {
    setLoading(true)

    const params = {
      TASK_CHECK_LIST_CONFIG: shiftScreenValue,
      TAKE_NON_CASH_TIPS_CONFIG: takeNonCashTipsValue,
      DECLARE_TIPS_CONFIG: declareTipsValue,
    }

    const { data } = await ApiSaveTaskCheckListConfig(params)

    if (data) {
      message.success('success', 2)
      setIsEdit(false)
      setInitData(
        {
          shiftScreenValue,
          takeNonCashTipsValue,
          declareTipsValue
        }
      )
    }

    setLoading(false)
  };

  const queryOrderBySeatConfig = async () => {
    setLoading(true)
    const { data } = await ApiQueryTaskCheckListConfig()

    if (data)  {
      setShiftScreenValue(data?.TASK_CHECK_LIST_CONFIG || 2)
      setTakeNonCashTipsValue(data?.TAKE_NON_CASH_TIPS_CONFIG || 2)
      setDeclareTipsValue(data?.DECLARE_TIPS_CONFIG || 1)
      setInitData(
        {
          shiftScreenValue: data?.TASK_CHECK_LIST_CONFIG || 2,
          takeNonCashTipsValue: data?.TAKE_NON_CASH_TIPS_CONFIG || 2,
          declareTipsValue: data?.DECLARE_TIPS_CONFIG || 1
        }
      )

    }

    setLoading(false)
  }


  useEffect(() => {
    queryOrderBySeatConfig()
  }, [])

  return (
    <Spin spinning={loading}>
      <div className="shiftScreen">
        <div className="top">
          <div className="btn-box" style={{ textAlign: 'right' }}>
            {isEdit ? (
              <>
                <Button
                  onClick={() => cancelEdit()}
                  style={{ marginRight: '16px' }}
                >
                  {i18n.t('cancel')}
                </Button>
                <Button onClick={() => saveOrderScreenConfig()} type="primary">
                  {i18n.t('save')}
                </Button>
              </>
            ) : (
              <Button
                onClick={() => setIsEdit(true)}
                type="primary"
                icon={<EditOutlined />}
              >
                {i18n.t('edit_1')}
              </Button>
            )}
          </div>
        </div>

        <div>
          <div style={{ fontWeight: 600  }}>{i18n.t('Dashboard_FOH_UI_option_check_out')}</div>

          <div style={{ margin: '10px 0' }}>
            {i18n.t('Dashboard_FOH_UI_option_check_out_task_list')}
          </div>

          <Radio.Group onChange={e => setShiftScreenValue(e.target.value)} value={shiftScreenValue} disabled={!isEdit}>
            <Space direction="vertical">
              <Radio value={1}>{i18n.t('Dashboard_required')}</Radio>
              <Radio value={2}>{i18n.t('Dashboard_optional')}</Radio>
            </Space>
          </Radio.Group>
        </div>

        <div style={{ marginTop: 20 }}>
          <div style={{ margin: '10px 0' }}>
            {i18n.t('dashboard_FOH_ui_otion_check_screen_tip_out')}
          </div>

          <Radio.Group onChange={e => setTakeNonCashTipsValue(e.target.value)} value={takeNonCashTipsValue} disabled={!isEdit}>
            <Space direction="vertical">
              <Radio value={1}>{i18n.t('dashboard_FOH_ui_otion_check_screen_tip_out_option_1')}</Radio>
              <Radio value={2}>{i18n.t('dashboard_FOH_ui_otion_check_screen_tip_out_option_2 manually (outside the system)')}</Radio>
            </Space>
          </Radio.Group>
        </div>


        <div style={{ marginTop: 20 }}>
          <div style={{ margin: '10px 0' }}>
            {i18n.t('Dashboard_FOH_UI_option_shift_declare_tips')}
          </div>

          <Radio.Group onChange={e => setDeclareTipsValue(e.target.value)} value={declareTipsValue} disabled={!isEdit}>
            <Space direction="vertical">
              <Radio value={1}>{i18n.t('Dashboard_FOH_UI_option_shift_declare_tips_by_cash')}</Radio>
              <Radio value={2}>{i18n.t('Dashboard_FOH_UI_option_shift_declare_tips_by_total')}</Radio>
            </Space>
          </Radio.Group>
        </div>
      </div>

    </Spin>

  );
}
