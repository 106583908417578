import React, { useEffect, useRef, useState } from 'react'
import { Button, message } from 'antd'
import './index.scss'
import BreadCrumb from '@/Pages/Menus/component/BreadCrumb'
import GeneralInfo from '../component/GeneralInfo'
import TaxRates from '../component/TaxRates'
import PrepStations from '../component/PrepStations'
import Availability from './Availability'
import { ApiBatchSaveMenu, ApiQueryDefaultAndInherits, ApiBatchQueryMenuDetailsByGuids } from '@/request/Menus/CreateMenu'
import { IDefaultAndInheritsRes } from '../interface'
import { globalState } from '@/stores';
import { getQuerys } from '@/utils'
import menuStore from '../store'
import { observer } from 'mobx-react'
import { defaultAndInheritsData } from '../enum'
import { useNavigate } from 'react-router-dom';
import ChannelAvailability from '../component/ChannelAvailability'


export default observer(function CreateMenu () {
  const { i18n } = globalState;
  const navigate = useNavigate();

  const { menuId, menuName, form = '' } = getQuerys(location.href);

  // ** others definition **
  const [editDetail, setEditDetail] = useState<any>({});
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);

  // ** default data **
  const [defaultAndInherits, setDefaultAndInherits] = useState<IDefaultAndInheritsRes>(defaultAndInheritsData);

  // ** ref 定义 **
  const refGeneralInfo = useRef(null);
  const refTaxRates = useRef(null);
  const refPrepStations = useRef(null);
  const refAvailability = useRef(null);
  const refChannelAvailability = useRef(null);


  const getQueryDefaultAndInherits = async () => {
    const editParams = {
      params: {
        node: {
          sourceId: menuId,
          sourceType: 'Menu'
        }
      }
    }
    const data = await ApiQueryDefaultAndInherits(menuId ?  editParams : {});
    if (data.code === 0) {
      setDefaultAndInherits({ ...defaultAndInherits, ...data.data })
    }
  }

  const getMenuDetail = async () => {
    const data = await ApiBatchQueryMenuDetailsByGuids({ params: { guids: [menuId] } })
    if (data.code === 0) {
      setEditDetail(data.data[0])
    }
  }

  const parseTime = (timeStr) => {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const cancelMenu = async () => {
    navigate(-1)
  }

  const saveMenu = async () => {
    // ** 校验 **
    const isValid = await checkValidateFields();
    if (!isValid) return;

    // ** 获取值 **
    const formGeneralInfoData = refGeneralInfo.current.form.getFieldsValue()
    const formTaxRatesData = refTaxRates.current.form.getFieldsValue()
    const formPrepStationsData = refPrepStations.current.form.getFieldsValue()
    const formAvailabilityData = refAvailability.current.form.getFieldsValue()
    const formChannelAvailabilityData = refChannelAvailability.current.form.getFieldsValue()

    // ** 常量定义 **
    const initParamsInAPass = {
      SYS$META$URI: 'rms.overseas.MerchantManagementConsole.Menu.MenuVO_VO',
      taxInclude: false,
    }
    const initEditParamsInAPass = {
      archived: false,
      archivedTime: null,
      color: null,
      displayOrder: editDetail.displayOrder || null,
      importId: null,
      publishTime: null,
      uuidRef: null,
      version: 1
    }
    const alwaysObj = { value: 'always', displayName: 'All time' }
    const specialObj = { value: 'specificDaysAndTime', displayName: 'Specific date and time' }
    const daysOpts = {
      Monday: 'Mon',
      Tuesday: 'Tue',
      Wednesday: 'Wed',
      Thursday: 'Thu',
      Friday: 'Fri',
      Saturday: 'Sat',
      Sunday: 'Sun'
    }

    // ** 解构 **
    const {  name, foreignName, shortName, } = formGeneralInfoData
    const { overrideTaxRate, taxRates } = formTaxRatesData
    const { prepStations } = formPrepStationsData
    const { dayOption, timeOption, days, customTime, startTime, endTime } = formAvailabilityData
    const { timeRange } = refAvailability.current
    const { visibleChannelPOS, onlineOrdering, doordashVisible } = formChannelAvailabilityData

    console.log('formAvailabilityData==>', formAvailabilityData)
    console.log('timeRange==>', timeRange)

    // ** 数值二次处理 **
    // * days *
    const daysData = days ? days.map(item => ({ value: item, displayName: daysOpts[item] })) : null
    // * taxRates *
    const taxRatesData = taxRates ? taxRates.map(item => ({ uuid: item })) : []
    // * prepStations *
    const prepStationsData = prepStations ? prepStations.map(item => ({ uuid: item })) : []


    // ** params 拼接 **
    const paramsData = {
      name,
      foreignName,
      shortName,
      overrideTaxRate,
      taxRates: taxRatesData,
      prepStations: prepStationsData,
      availableTime: {
        SYS$META$URI: 'rms.overseas.MerchantManagementConsole.Menu.DayAndTimeRule_VO',
        dayOption: dayOption === 'always' ? alwaysObj : specialObj,
        timeOption: timeOption === 'always' ? alwaysObj : specialObj,
        days: daysData,
        startTime: typeof timeRange[0] === 'string' ? parseTime(timeRange[0]) : timeRange[0],
        endTime: typeof timeRange[1] === 'string' ? parseTime(timeRange[1]) : timeRange[1]
      },
      visibleChannelPOS,
      onlineOrdering,
      doordashVisible
    }
    // ** API FETCH **
    setSaveButtonLoading(true)
    const res = await ApiBatchSaveMenu({
      params: {
        menus: [{
          ...paramsData,
          ...initParamsInAPass,
          ...(menuId && initEditParamsInAPass),
          uuid: menuId,
        }] } })
    setSaveButtonLoading(false)
    if (res.code === 0 && res.data) {
      // ** save store **
      menuStore.setActiveMenu({ uuid: res.data[0].uuid, name: res.data[0].name })

      message.success('success', 3)
      navigate(-1)
    }
  }

  const checkValidateFields = async () => {
    try {
      await refGeneralInfo.current.form.validateFields();
      await refAvailability.current.form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  }

  useEffect(()=> {
    getQueryDefaultAndInherits()
    if (menuId) {
      getMenuDetail();
    }
  }, [])

  return (
    <div className='menus-create-menu'>
      <BreadCrumb
        slotAction={
          <div>
            <Button
              style={{ width: 88, marginRight: 15 }}
              onClick={cancelMenu}
            >{i18n.t('cancel')}</Button>
            <Button
              style={{ width: 88 }}
              type="primary"
              onClick={saveMenu}
              loading={saveButtonLoading}
            >{i18n.t('save')}</Button>
          </div>
        }
        needConfirm
        customTitle={form ? [{
          title: menuName,
        }] : menuId ? [{
          title: i18n.t('overseas_peppr_entrance_menu_builder'),
          router: '/menus/menuBuilder'
        }, {
          title: menuName,
        }] : [{
          title: i18n.t('overseas_peppr_entrance_menu_builder'),
          router: '/menus/menuBuilder'
        }, {
          title: i18n.t('create_menu'),
        }]}
      />

      <GeneralInfo
        title={i18n.t('general_info')}
        ref={refGeneralInfo}
        editInfo={editDetail}
      />

      <TaxRates
        pageType="menu"
        ref={refTaxRates}
        editInfo={editDetail}
        inheritName={i18n.t('restaurant')}
        defaultAndInherits={defaultAndInherits}
      />

      <PrepStations
        pageType="menu"
        ref={refPrepStations}
        editInfo={editDetail}
        inheritName={i18n.t('restaurant')}
        defaultAndInherits={defaultAndInherits}
        isShowInheritRadio={false}
      />

      <Availability
        ref={refAvailability}
        editInfo={editDetail}
      />

      <ChannelAvailability
        ref={refChannelAvailability}
        editInfo={editDetail}
      />
    </div>

  )
})