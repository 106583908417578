import React, { useEffect, useRef, useState } from 'react'
import { Button, message } from 'antd'
import './index.scss'
import { observer } from 'mobx-react';
import menuStore from '../store';
import BreadCrumb, { ICustomTitleItem } from '@/Pages/Menus/component/BreadCrumb'
import GeneralInfo from '../component/GeneralInfo'
import Report from '../component/Report'
import TaxRates from '../component/TaxRates'
import ModifierGroups from '../component/ModifierGroups'
import PrepStations from '../component/PrepStations'
import ChannelAvailability from '../component/ChannelAvailability'
import { globalState } from '@/stores'
import { getQuerys } from '@/utils'
import { IDefaultAndInheritsRes } from '../interface'
import { ApiBatchQueryMenuGroupDetailsByGuids, ApiQueryDefaultAndInherits, ApiSaveAndAssociatedWithMenu } from '@/request/Menus/CreateSubMenu'
import { useNavigate } from 'react-router-dom';
import { defaultAndInheritsData } from '../enum'

export default observer(function CreateSubMenu (props) {
  const { i18n } = globalState;
  const navigate = useNavigate();
  const { menuId, subMenuId, subMenuName, menuName = menuStore.getActiveMenu.name, form = '' } = getQuerys(location.href);
  const customTitle: Array<ICustomTitleItem> = !form ? ([
    {
      title: i18n.t('overseas_peppr_entrance_menu_builder'),
      router: '/menus/menuBuilder'
    }, {
      title: menuName,
      router: `/menus/createMenu?menuId=${menuId}&menuName=${menuName}`
    }
  ]) : []

  // ** others definition **
  const [editDetail, setEditDetail] = useState<any>({});
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);

  // ** default data **
  const [defaultAndInherits, setDefaultAndInherits] = useState<IDefaultAndInheritsRes>(defaultAndInheritsData);

  // ** ref definition **
  const refGeneralInfo = useRef(null);
  const refTaxRates = useRef(null);
  const refModifierGroups = useRef(null);
  const refPrepStations = useRef(null);
  const refChannelAvailability = useRef(null);
  const refReport = useRef(null);

  // ** Online Ordering
  const [pricingType, setPricingType] = useState<{ value: string, displayName: string }>(null);

  const getQueryDefaultAndInherits = async () => {
    const editParams = {
      params: {
        node: {
          sourceId: menuId,
          sourceType: 'Menu'
        }
      }
    }
    const data = await ApiQueryDefaultAndInherits(editParams);
    if (data.code === 0) {
      setDefaultAndInherits({ ...defaultAndInherits, ...data.data })
    }
  }

  const getSubMenuDetail = async () => {
    const data = await ApiBatchQueryMenuGroupDetailsByGuids({
      params: {
        groupId: menuId,
        guids: [subMenuId],
      }
    })
    if (data.code === 0) {
      setEditDetail(data.data[0])
      setPricingType(data.data[0].pricingType);
    }
  }

  const cancelMenu = async () => {
    navigate(-1)
  }

  const saveMenu = async () => {
    // ** 校验 **
    const isValid = await checkValidateFields();
    if (!isValid) return;

    // ** 获取值 **
    const formGeneralInfoData = refGeneralInfo.current.form.getFieldsValue()
    const formTaxRatesData = refTaxRates.current.form.getFieldsValue()
    const formPrepStationsData = refPrepStations.current.form.getFieldsValue()
    const formChannelAvailabilityData = refChannelAvailability.current.form.getFieldsValue()
    const formReport = refReport.current.form.getFieldsValue()

    // ** 不使用form的暴露值 **
    const { tableData } = refModifierGroups.current

    // ** 常量定义 **
    const initParamsInAPass = {
      SYS$META$URI: 'rms.overseas.MerchantManagementConsole.Menu.MenuGroupVO',
      inheritTaxInclude: false,
      onlineOrdering: false,
      taxInclude: false,
    }

    console.log('formGeneralInfoData', formGeneralInfoData);

    // ** 解构 **
    const { name, foreignName, shortName, color } = formGeneralInfoData
    const { overrideTaxRate, taxRates } = formTaxRatesData
    const { inheritPrepStation, prepStations } = formPrepStationsData
    const { visibleChannelPOS, onlineOrdering, doordashVisible } = formChannelAvailabilityData
    const { inheritPrepStation: inheritSalesCategory, salesCategory: salesCategories } = formReport
    // ** 数值二次处理 **
    // * taxRates *
    const taxRatesData = taxRates ? taxRates.map(item => ({ uuid: item })) : []
    // * prepStations *
    const prepStationsData = prepStations ? prepStations.map(item => ({ uuid: item })) : []

    // ** params 拼接 **
    const paramsData = {
      name,
      foreignName,
      shortName,
      color,
      overrideTaxRate,
      taxRates: taxRatesData,
      inheritPrepStation,
      prepStations: prepStationsData,
      modifierGroups: tableData,
      visibleChannelPOS,
      onlineOrdering,
      doordashVisible,
      inheritSalesCategory,
      salesCategories: salesCategories ? [{ id: salesCategories }] : [],
    }
    // ** API FETCH **
    setSaveButtonLoading(true)
    const res = await ApiSaveAndAssociatedWithMenu({
      params: {
        menuGroups: [{
          ...initParamsInAPass,
          ...paramsData,
          uuid: subMenuId
        }],
        node: {
          sourceId: menuId,
          sourceType: {
            displayName: 'Menu',
            value: 'Menu'
          }
        }
      }
    })
    setSaveButtonLoading(false)
    if (res.code === 0) {
      // ** save store **
      menuStore.setActiveMenu({ uuid: menuId, name: menuName })
      menuStore.setActiveSubMenu({ uuid:subMenuId, name: subMenuName })
      message.success('success', 3)
      navigate(-1)
    }
  }

  const checkValidateFields = async () => {
    try {
      await refGeneralInfo.current.form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  }

  useEffect(()=> {
    getQueryDefaultAndInherits()
    if (subMenuId) {
      getSubMenuDetail();
    } else {
      setEditDetail({
        visibleChannelPOS: true,
        onlineOrdering: false
      })
    }
  }, [])

  return (
    <div className='menus-create-sub-menu'>
      <BreadCrumb
        slotAction={
          <div>
            <Button
              style={{ width: 88, marginRight: 15 }}
              onClick={cancelMenu}
            >{i18n.t('cancel')}</Button>
            <Button
              style={{ width: 88 }}
              type="primary"
              onClick={saveMenu}
              loading={saveButtonLoading}
            >{i18n.t('save')}</Button>
          </div>
        }
        needConfirm
        customTitle={subMenuId ? customTitle.concat({ title: subMenuName } ) : customTitle.concat({ title: i18n.t('menubuilder_pc_create_submenu') } ) }
      />

      <GeneralInfo
        ref={refGeneralInfo}
        editInfo={editDetail}
        colorFilter={true}
      />

      <TaxRates
        pageType='subMenu'
        ref={refTaxRates}
        editInfo={editDetail}
        inheritName={menuName}
        defaultAndInherits={defaultAndInherits}
      />

      <ModifierGroups
        ref={refModifierGroups}
        inheritName={menuName}
        editInfo={editDetail}
        isShowInheritButton={false}
      />

      <PrepStations
        pageType='subMenu'
        ref={refPrepStations}
        editInfo={editDetail}
        inheritName={menuName}
        defaultAndInherits={defaultAndInherits}
      />

      <Report
        pageType='subMenu'
        ref={refReport}
        editInfo={editDetail}
        defaultAndInherits={defaultAndInherits}
        inheritName={menuName}
        isShowInheritRadio={false}
      />

      <ChannelAvailability
        ref={refChannelAvailability}
        editInfo={editDetail}
        pricingType={pricingType}
      />
    </div>
  )
})