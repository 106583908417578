import React, { useState, useEffect, useRef } from 'react';
import { Button, Spin, Modal, message, Select } from 'antd';
import {
  getDoordashStores,
  getSignupUrl,
  exchangeCode,
  initOnboarding,
  queryOnboardingStatus,
  triggerMenuPull,
  queryAuthStatus,
} from '@/request/Doordash';
import DoordashLogo from '@/assets/doordash.png';
import { getQuerys, formatBackI18n } from '@/utils';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { globalState } from '@/stores';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
const Integrations = () => {
  const navigate = useNavigate();
  const { i18n } = globalState;
  const [modal, contextHolder] = Modal.useModal();
  const [syncLocation, setSyncLocation] = useState(false);
  const [loginPopUp, setLoginPopUp] = useState(false);
  const [stores, setStores] = useState([]);
  const [doordashStatus, setDoordashStatus] = useState(null);
  const [activationStatus, setActivationStatus] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [loadingMap, setLoadingMap] = useState({
    retry: false,
    login: false,
    pageLoading: false,
    doordash: false,
  });
  const [menuPullVisible, setMenuPullVisible] = useState(false);
  // 对接的商家暂时前端写死
  const [connectPartnersList, setConnectPartnersList] = useState([
    {
      name: 'Doordash',
      doordashStoreUuid: 'b1cbdb60-53f6-49eb-9eeb-884cac446212',
      logo: DoordashLogo,
    },
  ],);
  const [validatingMenu, setValidatingMenu] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [storeErrorMessage, setStoreErrorMessage] = useState('');
  const [url, setUrl] = useState('');
  const [showSignup, setShowSignup] = useState(false);
  const intervalIdRef = useRef(null);
  const doorDashUrl = () => {
    return getSignupUrl({}).then((res) => {
      if (res.code === 0) {
        setUrl(res.data);
      }
    })
  }
  const fetchData = async () => {
    try {
      setLoadingMap({ ...loadingMap, pageLoading: true });
      const response = await getDoordashStores({ current: 1, pageSize: 100 });
      setLoadingMap({ ...loadingMap, pageLoading: false });
      if (response.code === 0) {
        const { list } = await response.data;
        setStoreErrorMessage('');
        list?.forEach((item) => {
          item.disabled = !item.isEligibleToOnboard
        });
        console.log(list)
        setStores(list || []);
        if (list.length === 1) {
          setSelectedStore(list[0].doordashStoreUuid);
        }
      } else {
        // 仅在exchangeCode后1h内有效（实际约59min），授权过期会返回code=60000，Dashboard需要引导用户重新登陆
        // 用户无权限时返回code=60001，Dashboard需要引导用户重新登陆
        // 门店数量为0且未完成过入驻时code=60002，Dashboard需要提供引导用户注册button
        // 门店数量为0且已完成过入驻时code=60003，Dashboard需要禁用注册
        switch (response.code) {
        case 60000:
        case 60001:
        case 60003:
          setShowSignup(false);
          break;
        case 60002:
          setShowSignup(true);
          break;
        default:
          setShowSignup(false);
          break;
        }
        setStoreErrorMessage(formatBackI18n(response.message, i18n) || '');
        setStores([]);
      }
    } catch (error) {
      console.log(error)
    }
  };
  const getDoordashStatus = async () => {
    try {
      const res = await queryOnboardingStatus({ forceQuery: false });
      if (res.code === 0) {
        const { status, processes, details, isActivated } = res.data;
        setValidatingMenu(processes);
        setErrorMessage(details)
        setDoordashStatus(status);
        setActivationStatus(isActivated);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onboardingClick = async (item) => {
    const VALID_STATUS_TYPES = [-1, 50, 60];
    const isValidStatus = VALID_STATUS_TYPES.includes(doordashStatus);
    if (isValidStatus) {
      setLoadingMap({ ...loadingMap, [item.name]: true });
      const res = await queryAuthStatus()
      setLoadingMap({ ...loadingMap, [item.name]: false });
      if (res.code === 0 && res.data === true) {
        fetchData();
        setSyncLocation(true);
        return;
      }
      setLoginPopUp(true);
    } else {
      setMenuPullVisible(true)
    }
  };
  // 去DD注册！
  const SignUp = async () => {
    await doorDashUrl();
    if (url) {
      setLoginPopUp(false);
      setSyncLocation(false);
      // window.open(url, '_blank');
      window.location.href = url;
    }
  }
  const exchangeCodeFunc = async () => {
    try {
      const res = await exchangeCode({ exchangeCode: getQuerys(location.href).code });
      if (res.code === 0) {
        setSyncLocation(true);
        fetchData();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const retry = async () => {
    setLoadingMap({ ...loadingMap, retry: true });
    const res = await triggerMenuPull();
    setLoadingMap({ ...loadingMap, retry: false });
    await getDoordashStatus()
  }
  const updateMenu = async () => {
    window.open('/menus/menuBuilder', '_blank');
  }
  const visibleOkBtn = async () => {
    setLoadingMap({ ...loadingMap, doordash: true });
    const res = await initOnboarding({ doordashStoreUuid: selectedStore });
    setLoadingMap({ ...loadingMap, doordash: false });
    if (res.code === 0) {
      const { status, processes, details } = res.data;
      setValidatingMenu(processes);
      setErrorMessage(details)
      setDoordashStatus(status);
      setSyncLocation(false);
      setMenuPullVisible(true);
    } else if (res.code === 60000) {
      setErrorMessage(formatBackI18n(res.message, i18n));
      setStores([]);
    } else {
      message.error(formatBackI18n(res.message, i18n));
    }
  }
  // 登录DD
  const continueBtn = () => {
    // 上线前要改成读取location
    // window.open(`https://identity.doordash.com/auth?response_type=code&redirect_uri=${location.href}?status=1&scope=*&state=test&client_id=1816264082131884040&prompt=none&layout=default`, '_blank');
    const redirectUrl = `https://identity.doordash.com/auth?response_type=code&redirect_uri=${location.href}?status=1&scope=*&state=test&client_id=1816264082131884040&prompt=none&layout=default`;
    window.location.href = redirectUrl;
  }
  useEffect(() => {
    const code = getQuerys(location.href).code || '';
    if (code) {
      exchangeCodeFunc()
    }
    getDoordashStatus()
    document.addEventListener('visibilitychange', getDoordashStatus);
    return () => {
      document.removeEventListener('visibilitychange', getDoordashStatus);
    };
  }, [])
  useEffect(() => {
    if (menuPullVisible) {
      let attempts = 0;
      const maxAttempts = 30; // 每次打开弹窗轮训5分钟
      intervalIdRef.current = setInterval(async () => {
        await getDoordashStatus()
        if (doordashStatus === 50) {
          //  success 提示弹窗！
          setMenuPullVisible(false);
          modal.success({
            title: i18n.t('dashboard_integrations_dd_success'),
            content: i18n.t('dashboard_integrations_dd_successDesc'),
            footer: null,
            closable: true,
            icon: <CheckCircleOutlined />
          });
        }
        attempts++;
        if (attempts >= maxAttempts || doordashStatus === 50) {
          clearInterval(intervalIdRef.current);
          setMenuPullVisible(false);
        }
      }, 10000)
    }
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    }
  }, [menuPullVisible]);

  return (
    <div className="integrations">
      <div className="m-title">
        <div className="title">{i18n.t('dashboard_integrations')}</div>
      </div>
      <Spin spinning={loadingMap.pageLoading}>
        <div className="title-h1">{i18n.t('dasboard_integrations_connectPartners')}</div>
        <div className="connect-partners-list">
          {connectPartnersList.map((item: any) => (
            <div className="item" key={item.doordashStoreUuid}>
              <div className="item-left">
                <img className='img-logo' src={item.logo || ''} alt="" />
                <div className="text">
                  <div className="text-title">{item.name}</div>
                </div>
              </div>
              <div className="item-right">
                {activationStatus && <span style={{ fontSize: '20px', color: '#73d13d', marginRight: '16px' }}><CheckCircleOutlined /></span>}
                <Button onClick={() => onboardingClick(item)} loading={loadingMap[item.name]}>{activationStatus ? 'Relink' : 'Add'}</Button>
              </div>
            </div>
          ))}
        </div>
      </Spin>
      <Modal
        title={i18n.t('dashboard_integrations_dd_addOrderPlatform')}
        open={loginPopUp}
        onCancel={() => setLoginPopUp(false)}
        onOk={() => {
          setLoginPopUp(false);
          continueBtn()
        }}
        okText={i18n.t('dashboard_integrations_cta_confirm')}
        cancelText={i18n.t('dashboard_integrations_cta_cancel')}
      >
        <div className="login-pop-up text">
          <p>
            {i18n.t('dashboard_integrations_dd_addOrderPlatformDesc')}
            {!activationStatus && (
              <a onClick={SignUp}>
                {i18n.t('dashboard_integrations_dd_signup')}
              </a>
            )}
          </p>
          <br />
          {!activationStatus && (
            <p>{i18n.t('dashboard_integrations_dd_addOrderPlatformDesc1')}</p>
          )}
        </div>
      </Modal>
      <Modal
        title={i18n.t('dashboard_integrations_dd_syncLocation')}
        open={syncLocation}
        onCancel={() => setSyncLocation(false)}
        loading={loadingMap.doordash}
        width={621}
        footer={
          <div className='popup-footer-box'>
            <Button onClick={() =>  setSyncLocation(false)}>{i18n.t('cancel')}</Button>
            {stores.length > 0 && <Button type='primary' disabled={!selectedStore} onClick={() => visibleOkBtn()}>{i18n.t('dashboard_integrations_cta_continue')}</Button>}
            {showSignup && <Button type='primary' onClick={() => SignUp()}>{i18n.t('dashboard_integrations_cta_merchantPortal')}</Button>}
            {stores.length == 0 && <Button type='primary' onClick={() => continueBtn()}>Re-signin DoorDash</Button>}
          </div>
        }
      >
        <div className="popup-onboarding">
          <div className="info">{i18n.t('dashboard_integrations_dd_syncLocationDesc')}</div>
          <div className="title-h1">{i18n.t('dashboard_integrations_dd_onboardingStore')} <span style={{ color: 'red' }}>*</span></div>
          <div>
            {stores.length === 1 ? <span>{stores[0].name}</span> :
              <Select
                style={{ width: '100%', display: 'block' }}
                onChange={(value) => {
                  setSelectedStore(value)
                }}
                status={stores.length === 0 ? 'error' : ''}
                disabled={stores.length === 0}
                placeholder={i18n.t('dashboard_integrations_cta_select')}
                options={stores}
                fieldNames={{ label: 'name', value: 'doordashStoreUuid' }}
              ></Select>
            }
          </div>
          {/* {stores.length === 0 && <div className='error'>{i18n.t('dashboard_integrations_dd_noStores')}</div>} */}
          {storeErrorMessage && <div className='error'>{storeErrorMessage}</div>}
          <div className="text">{i18n.t('dashboard_integrations_dd_syncLocationDisclaimer')}</div>
        </div>
      </Modal>
      <Modal
        title={i18n.t('dashboard_integrations_dd_validatingMenu')}
        open={menuPullVisible}
        footer={
          validatingMenu.find(item => item.status === 999) ? <div className='popup-footer-box'>
            <Button onClick={() => updateMenu()}>{i18n.t('dashboard_integrations_cta_updateMenu')}</Button>
            <Button type='primary' onClick={() => retry()} loading={loadingMap.retry}>{i18n.t('dashboard_integrations_cta_retry')}</Button>
          </div> : null
        }
        onCancel={() => setMenuPullVisible(false)}
      >
        <div className="validating-menu">
          {validatingMenu && validatingMenu.map(item => {
            return <div className="item-menu-status" key={item.process}>
              {item.status === 100 && <div className="icon-status success"><CheckCircleOutlined /></div>}
              {item.status === 999 && <div className="icon-status error"><CloseCircleOutlined /></div>}
              {item.status === 10 && <div className="icon-status blue"><ClockCircleOutlined /></div>}
              <div className="info" style={{ marginLeft: item.status === 0 ? '24px' : '0' }}>{item.process}</div>
            </div>})}
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </div>
      </Modal>
      {contextHolder}
    </div>
  )
}
export default Integrations;