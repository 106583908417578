import { service } from '@/request/index'
const MenuParams = {
  URI: 'rms.overseas.operation.configuration.menu.Menu.Menu',
  fieldGroup: [
    {
      groupName: '',
      queryItems: [
        {
          name: 'foreignName'
        }, {
          name: 'visibleChannelPOS'
        }, {
          name: 'inheritModifierGroup'
        }, {
          name: 'description'
        }, {
          name: 'onlineOrdering'
        }, {
          name: 'side'
        }, {
          name: 'importId'
        }, {
          name: 'archivedTime'
        }, {
          name: 'inheritPrepStation'
        }, {
          name: 'taxInclude'
        }, {
          name: 'inheritTaxInclude'
        }, {
          name: 'overrideTaxRate'
        }, {
          name: 'combo'
        }, {
          name: 'pricingType'
        }, {
          name: 'basePrice'
        }, {
          name: 'displayOrder'
        }, {
          name: 'archived'
        }, {
          name: 'inventory'
        }, {
          name: 'publishTime'
        }, {
          name: 'version'
        }, {
          name: 'uuidRef'
        }, {
          name: 'uuid'
        }, {
          name: 'alcoholContains'
        }, {
          name: 'itemImage'
        }, {
          name: 'shortName'
        }, {
          name: 'name'
        }, {
          name: 'color'
        }, {
          name: 'id'
        }, {
          name: 'createdBy'
        }, {
          name: 'createdTime'
        }, {
          name: 'lastModifiedBy'
        }, {
          name: 'modifiedTime'
        }
      ]
    }
  ],
  orderBy: [
    {
      name: 'createdTime',
      direction: 'desc'
    }
  ],
}

const MenuGroupParams = {
  URI: 'rms.overseas.operation.configuration.menu.MenuGroup.MenuGroup',
  fieldGroup: [
    {
      groupName: '',
      queryItems: [
        {
          name: 'foreignName'
        }, {
          name: 'visibleChannelPOS'
        }, {
          name: 'onlineOrdering'
        }, {
          name: 'importId'
        }, {
          name: 'archivedTime'
        }, {
          name: 'inheritPrepStation'
        }, {
          name: 'taxInclude'
        }, {
          name: 'inheritTaxInclude'
        }, {
          name: 'overrideTaxRate'
        }, {
          name: 'archived'
        }, {
          name: 'displayOrder'
        }, {
          name: 'publishTime'
        }, {
          name: 'version'
        }, {
          name: 'uuidRef'
        }, {
          name: 'uuid'
        }, {
          name: 'color'
        }, {
          name: 'shortName'
        }, {
          name: 'name'
        }, {
          name: 'id'
        }, {
          name: 'createdBy'
        }, {
          name: 'createdTime'
        }, {
          name: 'lastModifiedBy'
        }, {
          name: 'modifiedTime'
        }
      ]
    }
  ],
  orderBy: [
    {
      name: 'createdTime',
      direction: 'desc'
    }
  ],
}
const MenuItemList = {
  URI: 'rms.overseas.operation.configuration.menu.MenuItem.MenuItem',
  fieldGroup: [
    {
      groupName: '',
      queryItems: [
        {
          name: 'foreignName'
        }, {
          name: 'visibleChannelPOS'
        }, {
          name: 'inheritModifierGroup'
        }, {
          name: 'description'
        }, {
          name: 'onlineOrdering'
        }, {
          name: 'side'
        }, {
          name: 'importId'
        }, {
          name: 'archivedTime'
        }, {
          name: 'inheritPrepStation'
        }, {
          name: 'taxInclude'
        }, {
          name: 'inheritTaxInclude'
        }, {
          name: 'overrideTaxRate'
        }, {
          name: 'combo'
        }, {
          name: 'pricingType'
        }, {
          name: 'basePrice'
        }, {
          name: 'displayOrder'
        }, {
          name: 'archived'
        }, {
          name: 'inventory'
        }, {
          name: 'publishTime'
        }, {
          name: 'version'
        }, {
          name: 'uuidRef'
        }, {
          name: 'uuid'
        }, {
          name: 'alcoholContains'
        }, {
          name: 'itemImage'
        }, {
          name: 'shortName'
        }, {
          name: 'name'
        }, {
          name: 'color'
        }, {
          name: 'id'
        }, {
          name: 'createdBy'
        }, {
          name: 'createdTime'
        }, {
          name: 'lastModifiedBy'
        }, {
          name: 'modifiedTime'
        }
      ]
    }
  ],
  orderBy: [
    {
      name: 'createdTime',
      direction: 'desc'
    }
  ],
}
const MenuOptionGroupList = {
  URI: 'rms.overseas.operation.configuration.menu.MenuOptionGroup.MenuOptionGroup',
  fieldGroup: [
    {
      groupName: '',
      queryItems: [
        {
          name: 'foreignName'
        }, {
          name: 'combo'
        }, {
          name: 'archivedTime'
        }, {
          name: 'displayOrder'
        }, {
          name: 'archived'
        }, {
          name: 'requiredModeType'
        }, {
          name: 'groupPrice'
        }, {
          name: 'currency'
        }, {
          name: 'publishTime'
        }, {
          name: 'version'
        }, {
          name: 'uuid'
        }, {
          name: 'minSelections'
        }, {
          name: 'maxSelections'
        }, {
          name: 'duplicateModifiersEnabled'
        }, {
          name: 'multiSelect'
        }, {
          name: 'pricingMode'
        }, {
          name: 'buttonColor'
        }, {
          name: 'shortName'
        }, {
          name: 'name'
        }, {
          name: 'id'
        }, {
          name: 'createdBy'
        }, {
          name: 'createdTime'
        }, {
          name: 'lastModifiedBy'
        }, {
          name: 'modifiedTime'
        }
      ]
    }
  ],
  orderBy: [
    {
      name: 'createdTime',
      direction: 'desc'
    }
  ],
}
const MenuOptionList = {
  URI: 'rms.overseas.operation.configuration.menu.MenuOption.MenuOption',
  fieldGroup: [
    {
      groupName: '',
      queryItems: [
        {
          name: 'foreignName'
        }, {
          name: 'archivedTime'
        }, {
          name: 'displayOrder'
        }, {
          name: 'menuItemGuid'
        }, {
          name: 'archived'
        }, {
          name: 'publishTime'
        }, {
          name: 'version'
        }, {
          name: 'uuid'
        }, {
          name: 'currency'
        }, {
          name: 'name'
        }, {
          name: 'overridePrice'
        }, {
          name: 'modifierPrice'
        }, {
          name: 'posButtonColor'
        }, {
          name: 'id'
        }, {
          name: 'createdBy'
        }, {
          name: 'createdTime'
        }, {
          name: 'lastModifiedBy'
        }, {
          name: 'modifiedTime'
        }
      ]
    }
  ],
  orderBy: [
    {
      name: 'createdTime',
      direction: 'desc'
    }
  ],
}
function paramsFormat (obj, params) {
  return {
    params: {
      querySchema: {
        ...obj,
        ...params
      }
    }
  }
}
export const queryMenuList: any = params => service.post('rms/merchant/menu/list', params);
export const archiveMenu: any = params => service.post('rms/overseas/MerchantManagementConsole/Menu/archiveMenu', params);
export const restoreMenuArchive: any = params => service.post('rms/overseas/MerchantManagementConsole/Menu/restoreMenuArchive', params);

export const queryMenuGroupList: any = (params) => service.post('rms/merchant/menu/menugroup/list', params);
export const queryMenuOptionGroupList: any = params => service.post('rms/merchant/menu/modifiergroup/list', params);

// export const queryMenuItemList: any = params => service.post('rms/overseas/operation/configuration/menu/MenuItem/list', paramsFormat(MenuItemList, params))
export const queryMenuItemList: any = params => service.post('rms/merchant/menu/item/list', params)
export const viewArchiveUsage: any = params => service.post('/rms/overseas/MerchantManagementConsole/Menu/viewArchiveUsage', params)

// export const queryMenuOptionGroupList: any = params => service.post('rms/overseas/operation/configuration/menu/MenuOptionGroup/list', paramsFormat(MenuOptionGroupList, params));

export const queryMenuOptionList: any = params => service.post('rms/overseas/operation/configuration/menu/MenuOption/list',  params);